import { seekbusiness_com_au } from '@seek/online-identity';

import type { RenderConfig } from '../../types';

export default {
  hostConfigs: {
    [seekbusiness_com_au.clientDomain]: {
      clientConfig: seekbusiness_com_au,
      environment: {},
    },
  },
  hookDomain: 'seekbusiness',
  themeName: 'seekBusiness',
} as RenderConfig;
