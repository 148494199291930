import {
  hk_staging_jobsdb_com,
  www_staging_jobstreet_com_ph,
  staging_seek_co_nz,
  staging_seek_com_au,
  th_staging_jobsdb_com,
  my_staging_jobstreet_com,
  sg_staging_jobstreet_com,
  id_staging_jobstreet_com,
  ph_staging_jobstreet_com,
} from '@seek/online-identity';

import type { RenderConfig } from '../../types';

export default {
  hostConfigs: {
    'seek.com.au.local': {
      clientConfig: staging_seek_com_au,
      environment: {
        SITE: 'DEVELOPMENT_SEEK_AU',
        COUNTRY: 'Australia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'seek.co.nz.local': {
      clientConfig: staging_seek_co_nz,
      environment: {
        SITE: 'DEVELOPMENT_SEEK_NZ',
        COUNTRY: 'New Zealand',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'jobstreet.com.ph.local': {
      clientConfig: www_staging_jobstreet_com_ph,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'JOBSTREET_PH_DEVELOPMENT',
        COUNTRY: 'Philippines',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'hk.jobsdb.com.local': {
      clientConfig: hk_staging_jobsdb_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'JOBSDB_HK_DEVELOPMENT',
        COUNTRY: 'Hong Kong',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'th.jobsdb.com.local': {
      clientConfig: th_staging_jobsdb_com,
      privacyChkBoxText: 'asiaCandidateThaiPrivacyPolicy',
      environment: {
        SITE: 'JOBSDB_TH_DEVELOPMENT',
        COUNTRY: 'Thailand',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'my.jobstreet.com.local': {
      clientConfig: my_staging_jobstreet_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'DEVELOPMENT_JOBSTREET_MY',
        COUNTRY: 'Malaysia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'sg.jobstreet.com.local': {
      clientConfig: sg_staging_jobstreet_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'DEVELOPMENT_JOBSTREET_SG',
        COUNTRY: 'Singapore',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'id.jobstreet.com.local': {
      clientConfig: id_staging_jobstreet_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'DEVELOPMENT_JOBSTREET_ID',
        COUNTRY: 'Indonesia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'ph.jobstreet.com.local': {
      clientConfig: ph_staging_jobstreet_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'DEVELOPMENT_JOBSTREET_PH',
        COUNTRY: 'Philippines',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'au.seek.com.local': {
      clientConfig: staging_seek_com_au,
      environment: {
        SITE: 'DEVELOPMENT_SEEK_AU',
        COUNTRY: 'Australia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'nz.seek.com.local': {
      clientConfig: staging_seek_co_nz,
      environment: {
        SITE: 'DEVELOPMENT_SEEK_NZ',
        COUNTRY: 'New Zealand',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'my.seek.com.local': {
      clientConfig: my_staging_jobstreet_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'JOBSTREET_MY_DEVELOPMENT',
        COUNTRY: 'Malaysia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'sg.seek.com.local': {
      clientConfig: sg_staging_jobstreet_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'JOBSTREET_SG_DEVELOPMENT',
        COUNTRY: 'Singapore',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'id.seek.com.local': {
      clientConfig: id_staging_jobstreet_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'DEVELOPMENT_JOBSTREET_ID',
        COUNTRY: 'Indonesia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'ph.seek.com.local': {
      clientConfig: ph_staging_jobstreet_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'JOBSTREET_PH_DEVELOPMENT',
        COUNTRY: 'Philippines',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'hk.seek.com.local': {
      clientConfig: hk_staging_jobsdb_com,
      privacyChkBoxText: 'asiaCandidateStandardPrivacyPolicy',
      environment: {
        SITE: 'JOBSDB_HK_DEVELOPMENT',
        COUNTRY: 'Hong Kong',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    'th.seek.com.local': {
      clientConfig: th_staging_jobsdb_com,
      privacyChkBoxText: 'asiaCandidateThaiPrivacyPolicy',
      environment: {
        SITE: 'JOBSDB_TH_DEVELOPMENT',
        COUNTRY: 'Thailand',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
  },
  hookDomain: 'candidate',
  themeName: 'seekJobs',
  originWhitelist: [
    'https://candidate-ui-staging.certsynonprod.com',
    'https://app.certsynonprod.com',
    'https://app.seekpass-staging.com',
  ],
} as RenderConfig;
