import React, { useEffect } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import LoadingScreen from 'src/components/shared/LoadingScreen/LoadingScreen';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import { getLanguagePrefixedPath } from 'src/utils/getLanguage';

const LOGIN_PATH = '/oauth/login';

const Error = () => {
  const { language } = useAppContext();
  const { analytics } = useAnalyticsContext();
  const params = new URLSearchParams(window.location.search);
  const state = params.get('state') || '';
  const urlSuffix = `error_description=${encodeURI(state as string)}`;
  const url = getLanguagePrefixedPath(language, `${LOGIN_PATH}?${urlSuffix}`);

  useEffect(() => {
    analytics.trackPageView({
      eventName: 'login_error_displayed',
      currentPage: 'login error',
      alertShownType: 'error',
      alertShownMessage: state,
    });

    window.location.assign(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);

  return <LoadingScreen />;
};

export default Error;
