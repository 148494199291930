import type { TrackJS, TrackJSConfigureOptions } from 'trackjs';

import { isBrowser } from 'src/utils/device';

const getTrackJS = () => {
  const t: typeof TrackJS | undefined = isBrowser()
    ? window.TrackJS
    : undefined;
  return t;
};

export const track = (e: Error | string | any): void => {
  const t = getTrackJS();
  if (t) {
    t.track(e);
  }
};

export const log = (...args: any[]): void => {
  const t = getTrackJS();
  if (t) {
    t.console.log(...args);
  }
};

export const addMetadata = (key: string, value: string): void => {
  const t = getTrackJS();
  if (t) {
    t.addMetadata(key, value);
  }
};

export const configure = (options: TrackJSConfigureOptions): void => {
  const t = getTrackJS();
  if (t) {
    t.configure(options);
  }
};

const t = getTrackJS();
if (t) {
  try {
    const cookies = document.cookie.split(';');
    cookies.filter((c) => c.indexOf('a0.spajs') > -1);
    t.addMetadata('txs_cookie_count', String(cookies.length));

    const scopesInLocalStorage = localStorage.getItem('oi__scopes');
    if (scopesInLocalStorage) {
      t.addMetadata('scopes_ls_value', scopesInLocalStorage);
    }
  } catch {
    // eslint-disable-next-line no-console
    console.warn(`Could not track metrics for OI.`);
  }
}

export default t;
