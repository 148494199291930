// const whitelist = ['https://www.disney.com'];

export const isQualifiedUrl = (value: string) => /^https?:\/\//.test(value);

export default (
  origin: string,
  pathname = '/',
  originWhitelist: string[] = [],
) => {
  if (!origin) {
    throw new Error('Origin is required');
  }

  if (isQualifiedUrl(pathname)) {
    const validPath = originWhitelist.find((whitelistedPath) =>
      pathname.startsWith(whitelistedPath),
    );
    if (validPath) {
      return pathname;
    }
    throw new Error('Cannot redirect to non-whitelisted origin');
  }

  return `${origin}${pathname.indexOf('/') === 0 ? pathname : `/${pathname}`}`;
};
