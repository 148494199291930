import React from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import * as metrics from 'src/modules/tracking/metrics';
import { track } from 'src/modules/tracking/trackJs';

interface Props {
  path: string;
  Component: any;
}

export default (props: Props) => {
  const { Component } = props;
  try {
    const appContext = useAppContext();
    const tags = [
      `site:${appContext.site}`,
      `tenant:${appContext.tenant}`,
      `hookDomain:${appContext.renderConfig.hookDomain}`,
      `clientDomain:${appContext.hostConfig.clientConfig.clientDomain}`,
    ];
    metrics.pageHit(props.path, tags);
  } catch (error) {
    track(error);
  }
  return <Component {...props} />;
};
