import { Stack, Loader } from 'braid-design-system';
import * as React from 'react';

import Logo from '../Logo/Logo';
import MainLayout from '../MainLayout/MainLayout';

import * as styles from './LoadingScreen.css';

const LoadingScreen = () => (
  <MainLayout>
    <Stack space="none" align="center">
      <div className={styles.loader}>
        <Loader />
      </div>
      <div className={styles.logo}>
        <Logo />
      </div>
    </Stack>
  </MainLayout>
);

export default LoadingScreen;
