import React from 'react';

import Login from '../Login/Login';

const ForgotPassword = () => (
  <>
    <Login path="/forgot-password" />
  </>
);

export default ForgotPassword;
