import React from 'react';

const SeekLogo = () => (
  <svg
    id="sk-logo-pos"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    height="50px"
    viewBox="0 0 212.789 83.777"
    width="150px"
    y="0px"
    x="0px"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="m108.46 56.818c-4.764 0-9.068-0.526-12.902-4.362l4.94-4.939c2.502 2.497 5.756 2.846 8.079 2.846 2.617 0 5.346-0.87 5.346-3.136 0-1.513-0.811-2.559-3.194-2.792l-4.767-0.464c-5.464-0.521-8.833-2.903-8.833-8.487 0-6.274 5.518-9.646 11.681-9.646 4.708 0 8.659 0.814 11.566 3.544l-4.648 4.708c-1.744-1.568-4.42-2.031-7.035-2.031-3.02 0-4.299 1.394-4.299 2.904 0 1.105 0.465 2.384 3.138 2.616l4.769 0.466c5.982 0.58 9.01 3.778 9.01 8.893-0.01 6.682-5.71 9.88-12.86 9.88z" />
    <path d="m131.53 43.68c0 3.895 2.384 6.743 6.628 6.743 3.31 0 4.938-0.93 6.856-2.848l4.593 4.474c-3.08 3.082-6.045 4.77-11.51 4.77-7.148 0-14.005-3.255-14.005-15.522 0-9.881 5.346-15.46 13.191-15.46 8.428 0 13.194 6.164 13.194 14.475v3.369h-18.95zm10.82-8.43c-0.818-1.798-2.504-3.136-5.06-3.136-2.56 0-4.243 1.337-5.057 3.136-0.466 1.107-0.637 1.922-0.697 3.26h11.511c-0.06-1.339-0.23-2.153-0.69-3.26z" />
    <path d="m161.35 43.68c0 3.895 2.386 6.743 6.625 6.743 3.314 0 4.942-0.93 6.86-2.848l4.592 4.474c-3.081 3.082-6.046 4.77-11.508 4.77-7.15 0-14.01-3.255-14.01-15.522 0-9.881 5.349-15.46 13.196-15.46 8.427 0 13.194 6.164 13.194 14.475v3.369h-18.95zm10.81-8.43c-0.815-1.798-2.502-3.136-5.057-3.136-2.56 0-4.246 1.337-5.059 3.136-0.466 1.107-0.64 1.922-0.697 3.26h11.51c-0.05-1.339-0.22-2.153-0.69-3.26z" />
    <path d="m203.43 56.471l-7.436-12.619-3.204 3.61v9.008h-7.553v-41.387h7.553v23.484l10.058-12.382h9.129l-10.813 12.207 11.623 18.079h-9.36z" />
    <circle cx="41.9" cy="41.9" strokeWidth="0" r="41.9" fill="#0D3880" />
    <path
      d="m42.883 15.978c0-1.447 1.168-2.62 2.612-2.62 1.451 0 2.622 1.173 2.622 2.62 0 1.442-1.171 2.615-2.622 2.615-1.444 0-2.612-1.173-2.612-2.615z"
      fill="#fff"
    />
    <path
      d="m51.026 24.778c0-1.597 1.294-2.889 2.884-2.889 1.598 0 2.89 1.292 2.89 2.889 0 1.593-1.292 2.886-2.89 2.886-1.59 0-2.884-1.293-2.884-2.886z"
      fill="#fff"
    />
    <path
      d="m42.883 24.778c0-1.447 1.168-2.616 2.612-2.616 1.451 0 2.622 1.169 2.622 2.616 0 1.443-1.171 2.619-2.622 2.619-1.444 0-2.612-1.176-2.612-2.619z"
      fill="#fff"
    />
    <path
      d="m59.697 33.608c0-1.794 1.449-3.243 3.244-3.243 1.793 0 3.238 1.448 3.238 3.243 0 1.791-1.445 3.241-3.238 3.241-1.795 0-3.244-1.45-3.244-3.241z"
      fill="#fff"
    />
    <path
      d="m51.026 33.581c0-1.596 1.294-2.887 2.884-2.887 1.598 0 2.89 1.292 2.89 2.887 0 1.593-1.292 2.884-2.89 2.884-1.59 0-2.884-1.291-2.884-2.884z"
      fill="#fff"
    />
    <path
      d="m42.883 33.581c0-1.446 1.168-2.618 2.612-2.618 1.451 0 2.622 1.171 2.622 2.618s-1.171 2.618-2.622 2.618c-1.444 0-2.612-1.171-2.612-2.618z"
      fill="#fff"
    />
    <path
      d="m35.099 33.581c0-1.265 1.024-2.289 2.291-2.289 1.27 0 2.292 1.024 2.292 2.289s-1.023 2.291-2.292 2.291c-1.266 0-2.291-1.026-2.291-2.291z"
      fill="#fff"
    />
    <path
      d="m28.611 33.581c0-1.085 0.878-1.966 1.963-1.966 1.083 0 1.961 0.881 1.961 1.966 0 1.084-0.879 1.964-1.961 1.964-1.084 0-1.963-0.879-1.963-1.964z"
      fill="#fff"
    />
    <path
      d="m22.425 33.581c0-0.906 0.731-1.637 1.636-1.637 0.908 0 1.637 0.731 1.637 1.637s-0.729 1.638-1.637 1.638c-0.905 0-1.636-0.732-1.636-1.638z"
      fill="#fff"
    />
    <path
      d="m17.189 33.581c0-0.725 0.589-1.309 1.313-1.309 0.719 0 1.309 0.584 1.309 1.309 0 0.726-0.59 1.308-1.309 1.308-0.724 0-1.313-0.582-1.313-1.308z"
      fill="#fff"
    />
    <path
      d="m12.233 33.581c0-0.544 0.438-0.983 0.982-0.983 0.543 0 0.981 0.439 0.981 0.983 0 0.542-0.438 0.981-0.981 0.981-0.544 0.001-0.982-0.438-0.982-0.981z"
      fill="#fff"
    />
    <path
      d="m8.045 33.581c0-0.363 0.295-0.653 0.658-0.653 0.358 0 0.649 0.291 0.649 0.653 0 0.361-0.291 0.656-0.649 0.656-0.363 0-0.658-0.295-0.658-0.656z"
      fill="#fff"
    />
    <path
      d="m59.697 42.408c0-1.786 1.449-3.243 3.244-3.243 1.793 0 3.238 1.458 3.238 3.243 0 1.793-1.445 3.241-3.238 3.241-1.795-0.001-3.244-1.448-3.244-3.241z"
      fill="#fff"
    />
    <path
      d="m51.026 42.381c0-1.594 1.294-2.884 2.884-2.884 1.598 0 2.89 1.29 2.89 2.884 0 1.596-1.292 2.89-2.89 2.89-1.59 0-2.884-1.294-2.884-2.89z"
      fill="#fff"
    />
    <path
      d="m42.883 42.381c0-1.448 1.168-2.616 2.612-2.616 1.451 0 2.622 1.168 2.622 2.616 0 1.446-1.171 2.619-2.622 2.619-1.444 0-2.612-1.173-2.612-2.619z"
      fill="#fff"
    />
    <path
      d="m35.099 42.381c0-1.266 1.024-2.289 2.291-2.289 1.27 0 2.292 1.023 2.292 2.289s-1.023 2.292-2.292 2.292c-1.266 0-2.291-1.026-2.291-2.292z"
      fill="#fff"
    />
    <path
      d="m28.611 42.381c0-1.085 0.878-1.963 1.963-1.963 1.083 0 1.961 0.878 1.961 1.963 0 1.083-0.879 1.964-1.961 1.964-1.084 0.001-1.963-0.88-1.963-1.964z"
      fill="#fff"
    />
    <path
      d="m22.425 42.381c0-0.906 0.731-1.635 1.636-1.635 0.908 0 1.637 0.729 1.637 1.635s-0.729 1.639-1.637 1.639c-0.905 0-1.636-0.732-1.636-1.639z"
      fill="#fff"
    />
    <path
      d="m17.189 42.381c0-0.725 0.589-1.309 1.313-1.309 0.719 0 1.309 0.584 1.309 1.309 0 0.724-0.59 1.308-1.309 1.308-0.724 0-1.313-0.584-1.313-1.308z"
      fill="#fff"
    />
    <path
      d="m12.233 42.381c0-0.541 0.438-0.98 0.982-0.98 0.543 0 0.981 0.439 0.981 0.98 0 0.544-0.438 0.98-0.981 0.98-0.544 0.001-0.982-0.436-0.982-0.98z"
      fill="#fff"
    />
    <path
      d="m8.045 42.381c0-0.362 0.295-0.656 0.658-0.656 0.358 0 0.649 0.293 0.649 0.656s-0.291 0.657-0.649 0.657c-0.363 0.001-0.658-0.294-0.658-0.657z"
      fill="#fff"
    />
    <path
      d="m68.59 42.468c0-1.963 1.595-3.553 3.552-3.553 1.961 0 3.549 1.589 3.549 3.553 0 1.958-1.588 3.549-3.549 3.549-1.957 0-3.552-1.591-3.552-3.549z"
      fill="#fff"
    />
    <path
      d="m59.697 51.209c0-1.79 1.449-3.242 3.244-3.242 1.793 0 3.238 1.452 3.238 3.242 0 1.792-1.445 3.243-3.238 3.243-1.795 0.001-3.244-1.451-3.244-3.243z"
      fill="#fff"
    />
    <path
      d="m51.026 51.186c0-1.597 1.294-2.889 2.884-2.889 1.598 0 2.89 1.292 2.89 2.889 0 1.593-1.292 2.886-2.89 2.886-1.59-0.001-2.884-1.293-2.884-2.886z"
      fill="#fff"
    />
    <path
      d="m42.883 51.186c0-1.447 1.168-2.62 2.612-2.62 1.451 0 2.622 1.173 2.622 2.62 0 1.445-1.171 2.617-2.622 2.617-1.444-0.001-2.612-1.173-2.612-2.617z"
      fill="#fff"
    />
    <path
      d="m35.099 51.186c0-1.266 1.024-2.294 2.291-2.294 1.27 0 2.292 1.028 2.292 2.294 0 1.268-1.023 2.288-2.292 2.288-1.266 0-2.291-1.021-2.291-2.288z"
      fill="#fff"
    />
    <path
      d="m28.611 51.186c0-1.088 0.878-1.963 1.963-1.963 1.083 0 1.961 0.875 1.961 1.963 0 1.085-0.879 1.962-1.961 1.962-1.084-0.001-1.963-0.877-1.963-1.962z"
      fill="#fff"
    />
    <path
      d="m22.425 51.186c0-0.909 0.731-1.636 1.636-1.636 0.908 0 1.637 0.727 1.637 1.636 0 0.902-0.729 1.634-1.637 1.634-0.905-0.001-1.636-0.732-1.636-1.634z"
      fill="#fff"
    />
    <path
      d="m17.189 51.186c0-0.722 0.589-1.311 1.313-1.311 0.719 0 1.309 0.589 1.309 1.311 0 0.724-0.59 1.31-1.309 1.31-0.724 0-1.313-0.587-1.313-1.31z"
      fill="#fff"
    />
    <path
      d="m12.233 51.186c0-0.543 0.438-0.985 0.982-0.985 0.543 0 0.981 0.442 0.981 0.985 0 0.541-0.438 0.982-0.981 0.982-0.544-0.001-0.982-0.441-0.982-0.982z"
      fill="#fff"
    />
    <path
      d="m8.045 51.186c0-0.362 0.295-0.658 0.658-0.658 0.358 0 0.649 0.296 0.649 0.658 0 0.359-0.291 0.649-0.649 0.649-0.363 0-0.658-0.291-0.658-0.649z"
      fill="#fff"
    />
    <path
      d="m51.026 59.988c0-1.596 1.294-2.889 2.884-2.889 1.598 0 2.89 1.293 2.89 2.889s-1.292 2.887-2.89 2.887c-1.59 0-2.884-1.291-2.884-2.887z"
      fill="#fff"
    />
    <path
      d="m42.883 59.988c0-1.445 1.168-2.624 2.612-2.624 1.451 0 2.622 1.178 2.622 2.624 0 1.443-1.171 2.617-2.622 2.617-1.444 0-2.612-1.173-2.612-2.617z"
      fill="#fff"
    />
    <path
      d="m42.883 68.787c0-1.443 1.168-2.613 2.612-2.613 1.451 0 2.622 1.17 2.622 2.613 0 1.447-1.171 2.621-2.622 2.621-1.444 0-2.612-1.174-2.612-2.621z"
      fill="#fff"
    />
  </svg>
);

export default SeekLogo;
