import Cookie from 'js-cookie';

export default (): {
  da_cdt: string | undefined;
} => {
  const da_cdt = Cookie.get('da_cdt');
  return {
    da_cdt,
  };
};
