import React from 'react';

import * as styles from './SeekBusinessLogo.css';

const SeekBusinessLogo = () => (
  <svg
    className={styles.svg}
    xmlns="http://www.w3.org/2000/svg"
    width="439.748"
    height="86.665"
    viewBox="0 0 329.81 65.01"
  >
    <path d="M157.86 43.82l-5.77-9.79-2.48 2.8v7h-5.87V11.71h5.87v18.22l7.8-9.61h7.08l-8.39 9.47 9 14zM84.16 44.09c-3.69 0-7-.41-10-3.38l3.8-3.84a8.55 8.55 0 006.29 2.22c2 0 4.15-.67 4.15-2.43 0-1.17-.63-2-2.48-2.17l-3.7-.36c-4.24-.4-6.85-2.25-6.85-6.58 0-4.87 4.28-7.49 9.06-7.49 3.66 0 6.72.63 9 2.75l-3.61 3.65c-1.35-1.21-3.43-1.57-5.46-1.57-2.34 0-3.33 1.08-3.33 2.25 0 .86.36 1.85 2.43 2l3.7.36c4.65.45 7 2.93 7 6.9-.03 5.21-4.45 7.69-10 7.69zM102.07 33.9c0 3 1.85 5.23 5.14 5.23a6.48 6.48 0 005.32-2.21l3.57 3.47a11.19 11.19 0 01-8.93 3.7c-5.55 0-10.87-2.52-10.87-12 0-7.67 4.15-12 10.24-12 6.53 0 10.23 4.78 10.23 11.23v2.62zm8.39-6.54a4.38 4.38 0 00-7.85 0 6.55 6.55 0 00-.54 2.52h8.89a6.37 6.37 0 00-.5-2.52zM125.21 33.9c0 3 1.85 5.23 5.14 5.23a6.5 6.5 0 005.32-2.21l3.56 3.47a11.19 11.19 0 01-8.93 3.7c-5.54 0-10.87-2.52-10.87-12 0-7.67 4.15-12 10.24-12 6.54 0 10.29 4.74 10.29 11.19v2.62zm8.39-6.54a4.38 4.38 0 00-7.85 0 6.37 6.37 0 00-.54 2.52h8.93a6.55 6.55 0 00-.54-2.52z" />
    <path
      fill="#0d3880"
      d="M-.04 32.51a32.51 32.51 0 1132.51 32.5A32.5 32.5 0 01-.04 32.51z"
    />
    <path
      fill="#fff"
      d="M33.28 12.4a2 2 0 112 2 2 2 0 01-2-2zM39.6 19.23a2.24 2.24 0 112.23 2.24 2.23 2.23 0 01-2.23-2.24zM33.28 19.23a2 2 0 112 2 2 2 0 01-2-2zM46.32 26.09a2.52 2.52 0 112.52 2.52 2.51 2.51 0 01-2.52-2.52zM39.6 26.09a2.24 2.24 0 112.23 2.24 2.23 2.23 0 01-2.23-2.24zM33.28 26.09a2 2 0 112 2 2 2 0 01-2-2zM27.24 26.09a1.78 1.78 0 111.77 1.78 1.78 1.78 0 01-1.77-1.78zM22.2 26.09a1.53 1.53 0 111.53 1.53 1.53 1.53 0 01-1.53-1.53zM17.4 26.09a1.27 1.27 0 111.27 1.27 1.27 1.27 0 01-1.27-1.27zM13.34 26.09a1 1 0 111 1 1 1 0 01-1-1zM9.49 26.09a.77.77 0 11.77.76.76.76 0 01-.77-.76zM6.24 26.09a.51.51 0 11.51.51.51.51 0 01-.51-.51zM46.32 32.91a2.52 2.52 0 112.52 2.51 2.52 2.52 0 01-2.52-2.51zM39.6 32.89a2.24 2.24 0 112.23 2.2 2.23 2.23 0 01-2.23-2.24zM33.28 32.89a2 2 0 112 2 2 2 0 01-2-2zM27.24 32.89a1.78 1.78 0 111.77 1.78 1.78 1.78 0 01-1.77-1.78zM22.2 32.89a1.53 1.53 0 111.53 1.52 1.52 1.52 0 01-1.53-1.52zM17.4 32.89a1.27 1.27 0 111.27 1.27 1.27 1.27 0 01-1.27-1.27zM13.34 32.89a1 1 0 112 0 1 1 0 01-2 0zM9.49 32.89a.77.77 0 11.77.76.76.76 0 01-.77-.76zM6.24 32.89a.51.51 0 11.51.51.51.51 0 01-.51-.51zM53.23 32.96a2.75 2.75 0 112.73 2.75 2.75 2.75 0 01-2.75-2.75zM46.32 39.74a2.52 2.52 0 112.52 2.52 2.52 2.52 0 01-2.52-2.52zM39.6 39.72a2.24 2.24 0 112.23 2.24 2.23 2.23 0 01-2.23-2.24zM33.28 39.72a2 2 0 112 2 2 2 0 01-2-2zM27.24 39.72a1.78 1.78 0 111.77 1.78 1.78 1.78 0 01-1.77-1.78zM22.2 39.72a1.53 1.53 0 111.53 1.52 1.52 1.52 0 01-1.53-1.52zM17.4 39.72a1.27 1.27 0 111.27 1.27 1.27 1.27 0 01-1.27-1.27zM13.34 39.72a1 1 0 111 1 1 1 0 01-1-1zM9.49 39.72a.77.77 0 11.77.76.76.76 0 01-.77-.76zM6.24 39.72a.51.51 0 11.51.51.51.51 0 01-.51-.51zM39.6 46.55a2.24 2.24 0 112.23 2.24 2.23 2.23 0 01-2.23-2.24zM33.28 46.55a2 2 0 112 2 2 2 0 01-2-2zM33.28 53.38a2 2 0 112 2 2 2 0 01-2-2z"
    />
    <path
      fill="#00a9e0"
      d="M185.27 41.96a8.17 8.17 0 01-5.72 2 7.53 7.53 0 01-6.63-3.1v2.81h-3.43V9.78h3.47v13.21a7.54 7.54 0 016.63-3 8.17 8.17 0 015.72 2c2.38 2.39 2.86 6.29 2.86 10s-.52 7.58-2.9 9.97zm-6.43-18.93c-5.11 0-5.92 4.39-5.92 9s.81 9 5.92 9 5.86-4.39 5.86-9-.74-9-5.86-9zM207.44 43.72v-2.63a8.6 8.6 0 01-6.63 2.91 8.3 8.3 0 01-6-2.2 8.73 8.73 0 01-2.38-6.57v-15h3.43v14.48c0 4.15 2.19 6.25 5.72 6.25s5.86-2.15 5.86-6.25V20.27h3.43v23.45zM223.96 44.01c-3.95 0-7.05-.91-9.62-3.39l2.28-2.28a9.62 9.62 0 007.3 2.71c3.86 0 6.24-1.38 6.24-4.14 0-2-1.19-3.2-3.91-3.44l-3.86-.33c-4.57-.38-7-2.43-7-6.29 0-4.29 3.63-6.87 8.63-6.87a13.25 13.25 0 018.44 2.53l-2.24 2.24a10 10 0 00-6.26-1.86c-3.43 0-5.24 1.48-5.24 3.86 0 2 1.14 3.2 4.1 3.43l3.77.34c4.1.38 6.91 2 6.91 6.34s-3.89 7.15-9.54 7.15zM237.14 13.6V9.73h3.82v3.87zm.19 30.12V20.27h3.43v23.45zM261.55 43.72V29.28c0-4.15-2.24-6.25-5.77-6.25s-5.82 2.15-5.82 6.25v14.44h-3.43V20.27h3.43v2.57a8.49 8.49 0 016.58-2.86 8.23 8.23 0 016 2.2 8.65 8.65 0 012.42 6.53v15zM272.61 32.9c0 5.15 2.43 8.11 6.91 8.11a7.73 7.73 0 006.15-2.67l2.33 2a10.71 10.71 0 01-8.58 3.63c-6.19 0-10.24-3.72-10.24-12 0-7.58 3.67-12 9.62-12s9.63 4.39 9.63 11.35v1.57zm11.68-6.48a6 6 0 00-11 0 9.86 9.86 0 00-.71 3.91h12.38a9.86 9.86 0 00-.67-3.91zM300.26 44.01c-4 0-7.06-.91-9.63-3.39l2.33-2.28a9.6 9.6 0 007.25 2.75c3.86 0 6.25-1.38 6.25-4.14 0-2-1.2-3.2-3.91-3.44l-3.87-.33c-4.57-.38-7-2.43-7-6.29 0-4.29 3.62-6.87 8.63-6.87a13.22 13.22 0 018.43 2.53l-2.24 2.24a10 10 0 00-6.24-1.86c-3.43 0-5.25 1.48-5.25 3.86 0 2 1.15 3.2 4.1 3.43l3.77.34c4.1.38 6.91 2 6.91 6.34s-3.83 7.11-9.53 7.11zM320.28 44.01c-4 0-7.05-.91-9.63-3.39l2.31-2.28a9.61 9.61 0 007.27 2.75c3.86 0 6.25-1.38 6.25-4.14 0-2-1.19-3.2-3.91-3.44l-3.86-.33c-4.58-.38-7-2.43-7-6.29 0-4.29 3.62-6.87 8.63-6.87a13.25 13.25 0 018.44 2.53l-2.25 2.24a10 10 0 00-6.24-1.86c-3.43 0-5.24 1.48-5.24 3.86 0 2 1.14 3.2 4.1 3.43l3.76.34c4.1.38 6.91 2 6.91 6.34s-3.86 7.11-9.54 7.11z"
    />
  </svg>
);

export default SeekBusinessLogo;
