import React, { useEffect } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import { useAuthN } from 'src/components/hooks/useAuthN';
import LoadingScreen from 'src/components/shared/LoadingScreen/LoadingScreen';
import type { AppLanguage } from 'src/types';
import { handleError } from 'src/utils/handleError';

const Integration = () => {
  const authN = useAuthN();
  const { language } = useAppContext();

  useEffect(() => {
    const initAuth = async () => {
      if (authN) {
        try {
          setTimeout(() => {
            throw new Error('Something went wrong. Please try again.');
          }, 3000);
          await authN.init();
        } catch (e: any) {
          handleError(e.message, language as AppLanguage);
        }
      }
    };

    initAuth();
  });

  return <LoadingScreen />;
};

export default Integration;
