import React, { useEffect } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import { useAuthN } from 'src/components/hooks/useAuthN';
import LoadingScreen from 'src/components/shared/LoadingScreen/LoadingScreen';
import getHook from 'src/modules/hooks/getHook';
import type { HookConfig, Hook } from 'src/modules/hooks/types';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import { trackHookDuration } from 'src/modules/tracking/metrics';
import type { AppLanguage } from 'src/types';
import getSafeReturnUrl from 'src/utils/getSafeReturnUrl';
import { handleError } from 'src/utils/handleError';

import { applyTrackingMetadata } from './applyTrackingMetadata';

const HOOK_TYPE: Hook = 'post-login';

const Callback = () => {
  const { analytics } = useAnalyticsContext();
  const authN = useAuthN();
  const {
    hostConfig,
    language,
    locale,
    renderConfig: { hookDomain, originWhitelist = [] },
  } = useAppContext();
  useEffect(() => {
    const exec = async () => {
      try {
        await authN.handleRedirect(async (origin: string, pathname: string) => {
          let returnPath = pathname;

          await applyTrackingMetadata(authN);

          const hook = await getHook(HOOK_TYPE, hookDomain);
          if (hook && !hostConfig.disableHooks) {
            const startTime = Date.now();
            const config: HookConfig = { authN, hostConfig, analytics };
            const hookResult = await hook(config, returnPath, language, locale);
            trackHookDuration(HOOK_TYPE, hookDomain!, startTime, hookResult);

            if (typeof hookResult === 'undefined') {
              return;
            }

            returnPath = hookResult;
          }

          const returnUrl = getSafeReturnUrl(
            origin,
            returnPath,
            originWhitelist,
          );

          window.location.assign(returnUrl);
        });
      } catch (e: any) {
        handleError(e, language as AppLanguage);
      }
    };

    exec();
  });

  return <LoadingScreen />;
};

export default Callback;
