import { gql } from '@apollo/client';

import { hirerGraphqlClient } from './hirerGraphqlClient';

export type CurrentAdvertiserResponse =
  | CurrentAdvertiserContext
  | MissingAdvertiserIdClaimError;

export interface CurrentAdvertiserContext {
  __typename: 'CurrentAdvertiserContext';
  /**
   * SeekWeb Advertiser ID.
   */
  seekId: string;
  /**
   * ID of the advertiser account.
   */
  advertiserId: string;
  /**
   * Optional billing ID provided by CRM.
   */
  billingId: string | null;
  /**
   * Name of the advertiser account.
   */
  name: string;
}

export interface MissingAdvertiserIdClaimError {
  __typename: 'MissingAdvertiserIdClaimError';
  message: string;
}

export interface CurrentAdvertiserRegistrationMFAEnrolledPayload {
  currentAdvertiser: CurrentAdvertiserResponse;
  registrationStatus?: {
    userExists: boolean;
  };
  isMfaEnroled: boolean;
}

export interface SendEnrolmentEmailResponse {
  sendEnrolmentEmail: {
    isEnrolmentEmailSent: boolean;
  };
}

const emitPostLoginMutation = gql`
  mutation emitPostLogin {
    sendLoginCallbackEvent {
      success
    }
    triggerActivation {
      success
    }
    sendAccessEvent {
      success
    }
    auditSuccessfulLogin {
      success
    }
  }
`;

const getAccountContextQuery = gql`
  query getCurrentAdvertiser {
    currentAdvertiser {
      ... on CurrentAdvertiserContext {
        __typename
        seekId
        advertiserId
        billingId
        name
      }
      ... on Error {
        __typename
        message
      }
    }
    registrationStatus {
      userExists
    }
    isMfaEnroled
  }
`;

const sendEnrolmentEmailMutation = gql`
  mutation sendEnrolmentEmail {
    sendEnrolmentEmail {
      isEnrolmentEmailSent
    }
  }
`;
export const gqlEmitPostLogin = async (token: string) => {
  await hirerGraphqlClient.mutate({
    mutation: emitPostLoginMutation,
    context: { token },
  });
};

export const gqlCurrentAdvertiserRegistrationMFAEnrolledQuery = async (
  token: string,
): Promise<CurrentAdvertiserRegistrationMFAEnrolledPayload> => {
  const response =
    await hirerGraphqlClient.query<CurrentAdvertiserRegistrationMFAEnrolledPayload>(
      {
        query: getAccountContextQuery,
        context: { token },
      },
    );
  return response.data;
};

export const sendEnrolmentEmail = async (
  token: string,
): Promise<SendEnrolmentEmailResponse> => {
  const response = await hirerGraphqlClient.mutate<SendEnrolmentEmailResponse>({
    mutation: sendEnrolmentEmailMutation,
    context: { token },
  });

  return response.data!;
};
