import React from 'react';

interface SecondfactorLockIlloType {
  height?: number;
}

const SecondfactorLockIllo = (props: SecondfactorLockIlloType) => {
  const { height } = props;

  return (
    <svg
      width="150"
      height={height ?? '204'}
      viewBox="0 0 150 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_1236)">
        <path
          d="M43.9697 163.472C102.902 196.557 198.404 84.3779 120.97 37.8129C53.7648 -2.58006 44.4332 70.79 31.1466 83.5823C5.3303 108.441 -10.4206 132.936 43.9697 163.472Z"
          fill="#FCD9EF"
        />
        <path
          d="M79.2407 106.255C89.8808 106.255 98.5064 97.629 98.5064 86.9889C98.5064 76.3488 89.8808 67.7233 79.2407 67.7233C68.6006 67.7233 59.9751 76.3488 59.9751 86.9889C59.9751 97.629 68.6006 106.255 79.2407 106.255Z"
          fill="#AD84F2"
        />
        <path
          d="M94.6673 188.755L97.1315 172.139L76.7148 177.91L76.4136 195.151L87.1124 197.554L94.6673 188.755Z"
          fill="#19A1AF"
        />
        <path
          d="M69.353 200.041V203.479H79.5498V201.161L81.8672 203.479H111.268V200.829C111.268 200.559 111.268 200.304 111.268 200.057L69.353 200.041Z"
          fill="#414C63"
        />
        <path
          d="M110.75 197.925C110.018 195.993 108.583 194.41 106.733 193.491L102.384 191.328L100.739 190.509L100.461 190.37L98.5219 189.404L95.7256 188.014C95.7256 188.014 95.3316 188.315 94.6595 188.786C92.5816 190.2 87.8385 193.112 83.9298 193.112C78.7696 193.112 74.5133 188.029 74.5133 188.029H72.304L69.9866 195.329C69.5716 196.625 69.3604 197.978 69.3608 199.338V200.111H111.26C111.191 199.362 111.019 198.627 110.75 197.925Z"
          fill="#020305"
        />
        <path
          d="M59.9751 173.545L60.8789 192.27C60.8789 192.27 66.6494 196.055 67.4759 196.465C68.3025 196.874 74.6214 197.554 74.6214 197.554L78.7928 195.151L81.2724 173.522L59.9751 173.545Z"
          fill="#19A1AF"
        />
        <path
          d="M84.8644 200.041V203.479H74.6677V201.161L72.3502 203.479H42.9497V200.829C42.9347 200.572 42.9347 200.314 42.9497 200.057L84.8644 200.041Z"
          fill="#414C63"
        />
        <path
          d="M84.2386 195.298L81.9212 187.998H79.7119C79.7119 187.998 75.4556 193.081 70.2954 193.081C66.433 193.081 61.6514 190.169 59.5657 188.755C58.8936 188.3 58.4996 187.983 58.4996 187.983L55.7033 189.373L53.7643 190.339L53.4863 190.478L51.8409 191.297L47.4918 193.46C45.642 194.379 44.2073 195.962 43.4749 197.894C43.2125 198.578 43.0461 199.296 42.9805 200.026H84.8721V199.253C84.8644 197.91 84.6507 196.576 84.2386 195.298Z"
          fill="#020305"
        />
        <path
          d="M67.4761 107.274L71.9874 179.447"
          stroke="#FD7532"
          strokeWidth="33.43"
          strokeLinejoin="round"
        />
        <path
          d="M90.0555 106.417L87.1123 175.785"
          stroke="#FD7532"
          strokeWidth="33.43"
          strokeLinejoin="round"
        />
        <path
          d="M79.2407 134.998L81.7822 167.141"
          stroke="#751704"
          strokeWidth="0.42"
          strokeMiterlimit="10"
        />
        <path
          d="M59.5117 134.998L62.2386 169.219"
          stroke="#751704"
          strokeWidth="0.27"
          strokeMiterlimit="10"
          strokeDasharray="1.61 1.61"
        />
        <path
          d="M64.6875 173.545H76.5605"
          stroke="#751704"
          strokeWidth="0.42"
          strokeMiterlimit="10"
        />
        <path
          d="M91.6235 172.139H97.703"
          stroke="#751704"
          strokeWidth="0.42"
          strokeMiterlimit="10"
        />
        <path
          d="M123.033 118.413C123.033 119.123 122.893 119.827 122.621 120.483C122.349 121.139 121.951 121.735 121.449 122.237C120.947 122.739 120.351 123.137 119.695 123.409C119.039 123.681 118.335 123.821 117.625 123.821H40.8488C40.1387 123.821 39.4355 123.681 38.7794 123.409C38.1234 123.137 37.5272 122.739 37.0251 122.237C36.523 121.735 36.1248 121.139 35.853 120.483C35.5813 119.827 35.4414 119.123 35.4414 118.413V55.5721C35.4414 54.138 36.011 52.7626 37.0251 51.7486C38.0392 50.7345 39.4146 50.1648 40.8488 50.1648H117.625C119.059 50.1648 120.435 50.7345 121.449 51.7486C122.463 52.7626 123.033 54.138 123.033 55.5721V118.413Z"
          fill="#19A1AF"
        />
        <path
          d="M56.0435 50.1957V40.4856C56.068 34.4648 58.4791 28.6995 62.7481 24.4537C67.017 20.2079 72.7953 17.8283 78.8161 17.8365H79.6582C85.6804 17.8262 91.4607 20.205 95.7313 24.451C100.002 28.697 102.414 34.4635 102.439 40.4856V50.1957H113.253V40.4856C113.217 31.5979 109.655 23.0879 103.351 16.8229C97.0471 10.5579 88.515 7.04967 79.6273 7.06816H78.7853C69.8983 7.04967 61.367 10.5581 55.0641 16.8233C48.7612 23.0886 45.2018 31.5986 45.167 40.4856V50.1957H56.0435Z"
          fill="#D2D7DF"
        />
        <path
          d="M88.8276 102.253C92.3424 100.407 96.058 99.9356 99.7581 101.481C102.393 102.703 105.258 103.349 108.163 103.373C108.47 103.31 108.789 103.352 109.069 103.492C109.349 103.632 109.574 103.862 109.708 104.146C109.777 104.323 109.484 104.802 109.26 104.918C108.546 105.209 107.794 105.396 107.027 105.474C104.827 105.651 102.619 105.257 100.616 104.331C97.9465 103.147 95.0334 102.618 92.1184 102.786C91.0447 102.786 89.9709 102.786 88.9049 102.786L88.8276 102.253Z"
          fill="#34BEC1"
        />
        <path
          d="M80.5078 80.9018C81.1661 80.7396 81.8389 80.6439 82.5162 80.6159C84.4474 80.6967 86.3733 80.8746 88.2866 81.1489C91.2221 81.6124 94.1343 82.1841 97.0929 81.3266C97.6877 81.1489 98.2826 81.1644 98.5839 81.8287C98.8851 82.4931 98.4139 82.972 97.8963 83.2192C97.1257 83.6797 96.2731 83.9866 95.3858 84.123C93.4005 84.2382 91.4086 84.1632 89.4376 83.899C87.2241 83.4711 85.037 82.9166 82.887 82.2381C82.0527 82.0064 81.2648 81.6356 80.4614 81.3266L80.5078 80.9018Z"
          fill="#34BEC1"
        />
        <path
          d="M35.8818 107.166C37.537 106.631 39.2816 106.428 41.0154 106.568C42.7493 106.709 44.4384 107.19 45.9858 107.985C47.5308 108.757 49.1299 109.53 50.9452 109.453C51.7177 109.414 52.4901 109.174 53.2626 109.136C53.6756 109.179 54.0795 109.286 54.46 109.453C54.3055 109.901 54.2901 110.542 53.9733 110.75C53.2671 111.213 52.4814 111.54 51.6559 111.716C49.9267 112.092 48.1197 111.817 46.5807 110.943C43.6197 109.341 40.3831 108.311 37.0406 107.908C36.6475 107.81 36.2605 107.688 35.8818 107.545C35.8818 107.436 35.8818 107.305 35.8818 107.166Z"
          fill="#34BEC1"
        />
        <path
          d="M67.0049 116.297C68.6885 115.56 70.5601 115.369 72.3581 115.748C73.9388 116.069 75.4894 116.524 76.993 117.108C78.7654 117.934 80.778 118.088 82.6553 117.54C83.3583 117.316 84.2002 116.915 84.7332 117.826C85.104 118.429 84.6329 119.07 83.4201 119.642C81.6422 120.479 79.6067 120.588 77.75 119.943C74.212 118.776 70.6819 117.587 67.0049 116.297Z"
          fill="#34BEC1"
        />
        <path
          d="M50.2886 83.729C51.934 82.6553 53.8497 82.833 55.7422 83.0338C56.5083 83.1374 57.2633 83.3106 57.9979 83.5514C60.5165 84.3495 63.1355 84.7864 65.7767 84.8491C66.1784 84.8491 66.8505 85.2122 66.9201 85.5212C66.933 85.7593 66.8877 85.997 66.7881 86.2137C66.6885 86.4304 66.5375 86.6196 66.3484 86.7649C65.8173 87.1221 65.1884 87.3057 64.5485 87.2902C62.8209 87.2184 61.1037 86.9856 59.4192 86.5949C56.3448 85.7607 53.3399 84.7023 50.2886 83.729Z"
          fill="#34BEC1"
        />
        <path
          d="M54.4211 101.365C56.5517 100.986 58.7286 100.947 60.8713 101.249C61.8956 101.374 62.9082 101.58 63.8995 101.867C66.1062 102.604 68.4437 102.867 70.7591 102.639C71.1222 102.585 71.5316 102.91 71.9178 103.064C71.7324 103.497 71.6552 104.092 71.323 104.331C70.7958 104.695 70.1842 104.919 69.5463 104.98C68.5193 105.119 67.477 105.098 66.4563 104.918C62.3931 103.953 58.3608 102.871 54.313 101.828L54.4211 101.365Z"
          fill="#34BEC1"
        />
        <path
          d="M107.784 66.1706C108.456 67.1825 107.869 67.6769 107.336 68.1249C106.424 68.9026 105.351 69.4677 104.194 69.779C103.037 70.0903 101.825 70.1401 100.646 69.9248C99.4924 69.7705 98.3799 69.3926 97.3708 68.8124C94.3504 66.9199 91.0056 66.441 87.5758 66.186C86.757 66.1242 85.9459 66.0084 84.8335 65.8848C85.8029 64.9873 87.0466 64.4429 88.3637 64.3398C91.6853 63.8763 94.9839 64.0463 97.8884 65.9311C99.0174 66.672 100.305 67.137 101.647 67.2887C102.989 67.4403 104.347 67.2743 105.613 66.804C106.37 66.6032 107.096 66.3714 107.784 66.1706Z"
          fill="#34BEC1"
        />
        <path
          d="M119.456 83.3737C119.232 84.4629 118.328 84.8646 117.409 85.1581C115.609 85.8101 113.686 86.0541 111.779 85.8724C109.873 85.6907 108.031 85.0879 106.386 84.1075C105.993 83.8755 105.616 83.6175 105.258 83.335C102.771 81.4115 100.221 79.6735 96.7144 80.0288C97.3175 79.3839 98.0847 78.9152 98.9338 78.673C99.7829 78.4308 100.682 78.4241 101.535 78.6538C103.475 79.1826 105.278 80.1262 106.818 81.4193C108.55 82.8111 110.715 83.5494 112.936 83.505C114.544 83.4014 116.145 83.2132 117.733 82.9411C118.359 82.8793 119.008 82.5317 119.456 83.3737Z"
          fill="#34BEC1"
        />
        <path
          d="M70.8442 54.9541C74.3513 53.1079 78.0669 52.6367 81.7671 54.1817C84.3998 55.41 87.2666 56.0556 90.1717 56.0743C90.4322 56.0363 90.6981 56.0655 90.9442 56.1592C91.2691 56.3028 91.5397 56.5464 91.7166 56.8545C91.7784 57.0321 91.4848 57.5111 91.2608 57.6269C90.5482 57.9133 89.7997 58.1004 89.0362 58.1831C86.8341 58.3525 84.625 57.9591 82.6168 57.0399C79.9502 55.8479 77.0351 55.3179 74.1195 55.4949C73.0458 55.4949 71.9797 55.4949 70.906 55.4949L70.8442 54.9541Z"
          fill="#34BEC1"
        />
        <path
          d="M39.4971 90.55C41.1524 90.0139 42.8975 89.8109 44.6317 89.9528C46.3659 90.0947 48.0549 90.5786 49.6011 91.3765C51.146 92.149 52.7451 92.9215 54.5604 92.8365C55.3329 92.7979 56.1054 92.5662 56.8779 92.5275C57.2903 92.5693 57.694 92.6735 58.0751 92.8365C57.9206 93.2846 57.8975 93.9257 57.5885 94.1343C56.8839 94.5997 56.0976 94.9274 55.271 95.0999C53.5394 95.4753 51.7301 95.2003 50.1882 94.3274C47.2311 92.7409 44.0032 91.7217 40.6712 91.3225C40.2584 91.238 39.8509 91.1297 39.4507 90.998L39.4971 90.55Z"
          fill="#34BEC1"
        />
        <path
          d="M39.8213 62.455C41.505 61.7191 43.3766 61.5273 45.1746 61.9065C46.7558 62.2254 48.3065 62.6803 49.8095 63.266C51.582 64.092 53.5943 64.2457 55.4717 63.6986C56.1824 63.4746 57.0167 63.0729 57.5575 63.9844C57.9205 64.587 57.4493 65.2282 56.2442 65.7998C54.4663 66.6377 52.4309 66.7458 50.5742 66.101C47.0286 64.9346 43.506 63.7064 39.8213 62.455Z"
          fill="#34BEC1"
        />
        <path
          d="M100.477 53.5406C102.201 53.2455 103.97 53.3272 105.66 53.78C107.297 54.1393 108.903 54.6248 110.465 55.2323C112.264 56.0007 114.292 56.0394 116.119 55.3404C116.581 55.1087 117.081 54.9647 117.595 54.9155C118.055 55.01 118.494 55.1852 118.892 55.4331C118.692 55.7884 118.584 56.2906 118.275 56.4837C116.119 57.851 113.732 58.5848 111.268 57.8046C107.769 56.6922 104.362 55.3018 100.925 54.0195C100.824 53.9809 100.77 53.8418 100.477 53.5406Z"
          fill="#34BEC1"
        />
        <path
          d="M49.9098 55.2477C52.0434 54.8719 54.2228 54.8353 56.3678 55.1396C57.3895 55.2644 58.3995 55.471 59.3882 55.7576C61.5949 56.4949 63.9324 56.7582 66.2478 56.5301C66.6109 56.4837 67.0202 56.8082 67.4141 56.9549C67.221 57.3875 67.1439 57.9823 66.8117 58.2218C66.2852 58.5874 65.6732 58.8109 65.0349 58.8707C64.008 59.0106 62.9655 58.9898 61.945 58.8088C57.8818 57.8432 53.8417 56.7618 49.7939 55.7189L49.9098 55.2477Z"
          fill="#34BEC1"
        />
        <path
          d="M79.2409 62.8875C84.0096 62.8875 88.6711 64.3017 92.6359 66.9512C96.6008 69.6006 99.691 73.3664 101.515 77.7722C103.34 82.178 103.817 87.026 102.886 91.7029C101.955 96.3798 99.6584 100.676 96.2859 104.047C92.9134 107.418 88.6168 109.714 83.9396 110.643C79.2624 111.573 74.4147 111.094 70.0094 109.268C65.6042 107.442 61.8394 104.351 59.1911 100.385C56.5429 96.4195 55.1303 91.7575 55.1319 86.9889C55.1319 83.8232 55.7555 80.6885 56.9672 77.7639C58.1788 74.8393 59.9548 72.182 62.1936 69.9439C64.4325 67.7058 67.0904 65.9307 70.0154 64.7199C72.9404 63.5092 76.0752 62.8865 79.2409 62.8875ZM79.2409 61.219C74.1419 61.2175 69.157 62.7282 64.9168 65.5601C60.6765 68.392 57.3713 72.4178 55.4193 77.1284C53.4673 81.8389 52.9561 87.0226 53.9505 92.0237C54.9449 97.0248 57.4001 101.619 61.0056 105.224C64.6111 108.83 69.205 111.285 74.2061 112.279C79.2072 113.274 84.3909 112.763 89.1014 110.811C93.812 108.859 97.8379 105.553 100.67 101.313C103.502 97.0728 105.012 92.0879 105.011 86.9889C105.007 80.1534 102.291 73.5987 97.4599 68.7631C92.6286 63.9275 86.0764 61.206 79.2409 61.1958V61.219Z"
          fill="#242831"
        />
        <path
          d="M88.17 109.33C100.542 104.409 106.583 90.3901 101.662 78.0177C96.7407 65.6454 82.7217 59.6049 70.3493 64.526C57.977 69.447 51.9366 83.4661 56.8576 95.8384C61.7787 108.211 75.7977 114.251 88.17 109.33Z"
          fill="white"
        />
        <path
          d="M79.2409 106.285C89.898 106.285 98.5374 97.6461 98.5374 86.9889C98.5374 76.3317 89.898 67.6924 79.2409 67.6924C68.5837 67.6924 59.9443 76.3317 59.9443 86.9889C59.9443 97.6461 68.5837 106.285 79.2409 106.285Z"
          fill="#748CF0"
        />
        <path
          d="M82.5275 95.0585C86.9792 93.2418 89.1153 88.1603 87.2986 83.7086C85.4819 79.257 80.4004 77.1209 75.9487 78.9376C71.497 80.7543 69.3609 85.8358 71.1776 90.2875C72.9943 94.7392 78.0758 96.8752 82.5275 95.0585Z"
          fill="#201F1B"
        />
        <path
          d="M73.6097 86.4868C76.4425 86.4868 78.739 84.1903 78.739 81.3575C78.739 78.5247 76.4425 76.2282 73.6097 76.2282C70.7769 76.2282 68.4805 78.5247 68.4805 81.3575C68.4805 84.1903 70.7769 86.4868 73.6097 86.4868Z"
          fill="white"
        />
        <path
          d="M70.7515 190.184C72.0314 190.184 73.069 189.147 73.069 187.867C73.069 186.587 72.0314 185.549 70.7515 185.549C69.4716 185.549 68.4341 186.587 68.4341 187.867C68.4341 189.147 69.4716 190.184 70.7515 190.184Z"
          fill="#28BFCE"
        />
        <path
          d="M87.1124 190.184C88.3922 190.184 89.4298 189.147 89.4298 187.867C89.4298 186.587 88.3922 185.549 87.1124 185.549C85.8325 185.549 84.7949 186.587 84.7949 187.867C84.7949 189.147 85.8325 190.184 87.1124 190.184Z"
          fill="#28BFCE"
        />
        <path
          d="M75.2705 134.998H82.8485"
          stroke="#751704"
          strokeWidth="0.42"
          strokeMiterlimit="10"
        />
        <path
          d="M116.382 61.219V75.8265"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          d="M101.774 9.26975L105.011 0.131317"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M105.011 12.9931L115.115 1.39047"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M111.252 16.4538L119.325 12.9931"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M-0.00016033 151.653C0.25086 151.553 0.522276 151.515 0.791178 151.542C1.06008 151.569 1.31857 151.66 1.5448 151.808C3.2906 152.897 5.05957 153.947 6.7513 154.975C6.7513 155.523 6.48086 155.57 6.19504 155.601C5.29277 155.649 4.3953 155.44 3.60726 154.998C2.49489 154.465 1.28985 154.063 0.447844 153.082C0.355146 152.966 0.138871 152.959 -0.015625 152.905L-0.00016033 151.653Z"
          fill="#F8B1DC"
        />
        <path
          d="M20.432 166.732C19.3185 166.777 18.2106 166.552 17.203 166.075C16.9404 165.921 16.6082 165.72 16.531 165.473C16.4653 165.301 16.4362 165.118 16.4455 164.934C16.4549 164.751 16.5024 164.571 16.5851 164.407C16.7252 164.265 16.8946 164.156 17.0814 164.086C17.2682 164.017 17.4679 163.989 17.6666 164.005C19.1266 164.214 20.5788 164.515 22.031 164.778C22.3404 164.823 22.6373 164.931 22.9039 165.094C23.1561 165.244 23.3496 165.476 23.4525 165.751C23.5529 166.137 23.1666 166.299 22.8808 166.353C22.0852 166.531 21.2663 166.616 20.432 166.732Z"
          fill="#F8B1DC"
        />
        <path
          d="M23.2903 128.401C22.0776 128.981 20.8802 128.78 19.7292 128.71C18.6391 128.586 17.5581 128.393 16.4926 128.131C16.2568 128.088 16.028 128.013 15.8128 127.907C15.5812 127.821 15.3909 127.651 15.2805 127.43C15.17 127.209 15.1477 126.954 15.218 126.717C15.3151 126.515 15.475 126.349 15.6741 126.244C15.8731 126.14 16.1007 126.103 16.3227 126.138C18.6264 126.568 20.8844 127.214 23.0664 128.069C23.1359 128.116 23.1745 128.239 23.2903 128.401Z"
          fill="#F8B1DC"
        />
        <path
          d="M43.9078 160.336C43.3902 160.714 43.1352 160.954 42.8031 161.108C41.3511 161.897 39.7886 162.462 38.1682 162.785C37.8675 162.848 37.5554 162.829 37.2645 162.73C37.1112 162.696 36.9705 162.619 36.8585 162.509C36.7465 162.399 36.6676 162.26 36.6306 162.107C36.5936 161.954 36.6001 161.794 36.6493 161.645C36.6985 161.496 36.7885 161.363 36.9091 161.263C37.1913 160.961 37.5446 160.736 37.9365 160.606C39.5821 160.093 41.3057 159.876 43.0271 159.965C43.3333 160.056 43.6285 160.181 43.9078 160.336Z"
          fill="#F8B1DC"
        />
        <path
          d="M29.7016 153.654C29.3463 153.916 29.2073 154.086 29.0296 154.133C28.4127 154.345 27.7718 154.48 27.1216 154.534C25.7413 154.545 24.3616 154.478 22.9888 154.334C22.4544 154.218 21.9511 153.989 21.5134 153.662C21.4179 153.571 21.3472 153.457 21.308 153.331C21.2688 153.206 21.2623 153.072 21.2893 152.943C21.3434 152.75 21.6679 152.634 21.8842 152.511C21.9854 152.483 22.092 152.483 22.1932 152.511C24.4102 152.82 26.6735 152.727 28.8519 153.337C29.0682 153.376 29.2691 153.484 29.7016 153.654Z"
          fill="#F8B1DC"
        />
        <path
          d="M39.5894 170.934C38.7185 171.597 37.6964 172.032 36.6153 172.201C35.7888 172.425 34.9468 172.595 34.1048 172.765C33.7317 172.826 33.3548 172.859 32.977 172.865C32.7854 172.887 32.5924 172.84 32.4324 172.733C32.2725 172.625 32.1561 172.464 32.1041 172.278C31.9805 171.861 32.2972 171.622 32.6062 171.506C33.0284 171.278 33.481 171.111 33.9503 171.011C35.4026 170.78 36.8626 170.602 38.3226 170.424C38.5605 170.373 38.8082 170.395 39.0339 170.486C39.2597 170.576 39.4532 170.733 39.5894 170.934Z"
          fill="#F8B1DC"
        />
        <path
          d="M31.1929 142.128C30.9379 142.978 30.2582 143.063 29.6479 143.102C28.5791 143.202 27.5032 143.202 26.4344 143.102C25.7974 142.993 25.1975 142.727 24.6886 142.329C24.1401 141.935 24.256 141.418 24.8662 141.132C25.1039 141.007 25.3701 140.945 25.6387 140.954C27.1836 141.217 28.7286 141.503 30.2736 141.804C30.5908 141.879 30.899 141.987 31.1929 142.128Z"
          fill="#F8B1DC"
        />
        <path
          d="M41.5514 131.275C41.6865 131.247 41.8258 131.247 41.9609 131.275C42.1926 131.376 42.5171 131.499 42.5789 131.685C42.6035 131.813 42.5982 131.945 42.5633 132.071C42.5285 132.197 42.4651 132.313 42.378 132.411C42.1059 132.677 41.7752 132.875 41.4124 132.99C40.4237 133.276 39.4194 133.515 38.4152 133.724C38.1064 133.769 37.7913 133.74 37.4959 133.639C37.3089 133.614 37.1374 133.522 37.0136 133.38C36.8898 133.238 36.8223 133.055 36.8239 132.866C36.8064 132.674 36.854 132.481 36.959 132.318C37.064 132.156 37.2204 132.033 37.4032 131.97C37.8914 131.808 38.3929 131.69 38.9019 131.615C39.7825 131.476 40.6631 131.376 41.5514 131.26V131.275Z"
          fill="#F8B1DC"
        />
        <path
          d="M14.175 172.433C13.9476 172.677 13.6515 172.845 13.3259 172.917C13.0004 172.989 12.6607 172.96 12.352 172.835C10.7469 172.609 9.20861 172.043 7.84061 171.174C7.70241 171.071 7.57081 170.96 7.44664 170.841C7.14537 170.563 6.94455 170.239 7.18402 169.837C7.29605 169.682 7.46152 169.575 7.64843 169.535C7.83534 169.495 8.03047 169.525 8.19601 169.621C8.9067 169.899 9.59416 170.216 10.2739 170.54C11.5408 171.143 12.8 171.768 14.175 172.433Z"
          fill="#F8B1DC"
        />
        <path
          d="M51.5782 147.914C50.9493 148.238 50.3019 148.524 49.6394 148.772C48.527 149.119 47.3837 149.374 46.2482 149.637C46.019 149.682 45.7816 149.655 45.5684 149.56C44.7959 149.189 44.7958 148.517 45.4988 148.061C45.6987 147.924 45.9209 147.822 46.1555 147.76C47.6718 147.393 49.2314 147.237 50.7903 147.296C51.0541 147.348 51.3126 147.423 51.5628 147.52L51.5782 147.914Z"
          fill="#F8B1DC"
        />
        <path
          d="M3.12848 128.401C5.00292 129.283 6.68951 130.519 8.09556 132.04L8.0337 132.287C7.58289 132.353 7.12494 132.353 6.67413 132.287C5.26157 131.917 3.97011 131.185 2.92763 130.163C2.28647 129.46 2.31738 129.097 3.12848 128.401Z"
          fill="#F8B1DC"
        />
        <path
          d="M14.3064 144.878C14.0576 145.092 13.7637 145.247 13.4467 145.331C13.1297 145.415 12.7978 145.427 12.4756 145.365C11.6333 145.21 10.8024 144.998 9.98828 144.732C9.55931 144.541 9.16511 144.28 8.82183 143.959C8.74383 143.903 8.67812 143.831 8.62862 143.749C8.57911 143.666 8.54685 143.575 8.53385 143.479C8.52085 143.384 8.52739 143.287 8.55299 143.194C8.57859 143.101 8.62277 143.015 8.68284 142.94C8.779 142.771 8.93478 142.646 9.11934 142.587C9.30389 142.528 9.50371 142.541 9.67927 142.623C10.6912 143.048 11.7032 143.473 12.6997 143.944C13.2095 144.145 13.673 144.492 14.3064 144.878Z"
          fill="#F8B1DC"
        />
        <path
          d="M97.9272 115.694C97.9272 115.694 105.374 115.455 105.652 109.151"
          stroke="#341B87"
          strokeWidth="1.37"
          strokeMiterlimit="10"
        />
        <path
          d="M69.7315 155.593C73.008 155.593 75.6641 152.937 75.6641 149.66C75.6641 146.384 73.008 143.727 69.7315 143.727C66.455 143.727 63.7988 146.384 63.7988 149.66C63.7988 152.937 66.455 155.593 69.7315 155.593Z"
          fill="#E05821"
        />
        <path
          d="M90.8511 155.593C94.1276 155.593 96.7837 152.937 96.7837 149.66C96.7837 146.384 94.1276 143.727 90.8511 143.727C87.5746 143.727 84.9185 146.384 84.9185 149.66C84.9185 152.937 87.5746 155.593 90.8511 155.593Z"
          fill="#E05821"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_1236">
          <rect width="150" height="203.479" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SecondfactorLockIllo;
