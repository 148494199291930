import React, { useEffect, useState } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import { useAuthN } from 'src/components/hooks/useAuthN';
import { track } from 'src/modules/tracking/trackJs';

import createAnalytics from './createAnalytics';
import type { Analytics } from './types';

interface ProviderProps {
  children: React.ReactNode;
}

interface State {
  analytics: Analytics;
}

const AnalyticsContext = React.createContext<State | undefined>(undefined);

export const useAnalyticsContext = () => {
  const context = React.useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error('AnalyticsContext must be used within a AnalyticsProvider');
  }

  return context;
};

export const AnalyticsProvider = (props: ProviderProps) => {
  const { locale, language, hostConfig } = useAppContext();
  const [isAuthenticated, setIsAuthenticated] = useState<null | boolean>(null);
  const authN = useAuthN();

  useEffect(() => {
    const getIsAuthenticated = async () => {
      try {
        setIsAuthenticated(await authN.isAuthenticated());
      } catch (e) {
        track(e);
        setIsAuthenticated(false);
      }
    };

    getIsAuthenticated();
  });

  if (isAuthenticated === null) {
    return null;
  }

  const analytics = createAnalytics({
    brand: hostConfig.clientConfig.brand || 'seek',
    isLoggedIn: isAuthenticated,
    siteCountry: locale,
    siteLanguage: language,
    zone: hostConfig.clientConfig.zone || 'anz-1',
  });

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};
