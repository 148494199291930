import { setContext } from '@apollo/client/link/context';
import Cookie from 'js-cookie';

import { isBrowser } from '../device';
import createGraphqlClient from '../gqlClient';

// standard SEEK headers https://rfc.skinfra.xyz/RFC031-Headers.html
const SEEK_REQUEST_EXPERIENCE_HEADER = 'seek-request-experience';
const SEEK_REQUEST_BRAND_HEADER = 'seek-request-brand';

// custom SEEK headers
const SEEK_REQUEST_SITE_HEADER = 'seek-request-site';

const createContextLink = () =>
  setContext(async (_, prevContext) => {
    const experience = 'hirer';
    const brand = 'seek';
    const seekSite = 'oauth-spa';

    return {
      ...prevContext,
      headers: {
        ...prevContext.headers,
        [SEEK_REQUEST_EXPERIENCE_HEADER]: experience,
        [SEEK_REQUEST_BRAND_HEADER]: brand,
        [SEEK_REQUEST_SITE_HEADER]: seekSite,
        'x-seek-site': seekSite, // keep x-seek-site until downstream replaced with seek-request-site
      },
    };
  });

// This logic is required for the advertiser UI verticals running on sku plugin to work effectively.
// They will run their apps locally and won't be able to reach the graphql endpoint locally. Therefore they can
// rely on the staging hirer graph instead to ingest their tokens and provide ids to hydrate local storage.
const devHostnameRegex =
  /talent\.seek\.(?:com\.au|co\.nz)\.(?:test|local)|.{2}\.employer\.seek\.com\.local/;
const origin = devHostnameRegex.test(
  isBrowser() ? window.location.hostname : '',
)
  ? 'https://talent.staging.seek.com.au'
  : '';

let url = `${origin}/graphql`;
const isUnified = Cookie.get('Unified') === 'true';
if (isUnified) {
  url += '?Unified=true';
}
const hirerGraphqlClient = createGraphqlClient({
  apiUri: url,
  credentials: 'omit',
  createContextLink,
});

export { hirerGraphqlClient };
