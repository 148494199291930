import type { Brand } from '@seek/melways-sites';
import { Box } from 'braid-design-system';
import React from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import type { Auth0TenantName } from 'src/utils/tenants';

import SeekAnzLogo from './resources/SeekAnzLogo';
import SeekBusinessLogo from './resources/SeekBusinessLogo';
import JobsDbLogoSvg from './resources/UnifiedJobsdbLogo';
import JobStreetLogoSG from './resources/UnifiedJobstreetLogo';

const getLogoForCandidate = (brand: Brand, headerLogo: boolean) => {
  if (brand === 'jobsdb') {
    return <JobsDbLogoSvg />;
  } else if (brand === 'jobstreet') {
    return <JobStreetLogoSG />;
  }
  return <SeekAnzLogo headerLogo={headerLogo} />;
};

export const getLogo = (
  tenant: Auth0TenantName,
  brand: Brand,
  headerLogo: boolean,
) => {
  switch (tenant) {
    case 'seekBusiness':
      return <SeekBusinessLogo />;
    default:
      return getLogoForCandidate(brand, headerLogo);
  }
};

interface LogoProps {
  headerLogo?: boolean;
}

const Logo = (props: LogoProps) => {
  const { headerLogo = false } = props;
  const defaultBrand = 'seek';
  const { tenant, hostConfig } = useAppContext();
  const brand = hostConfig.clientConfig.brand ?? defaultBrand;

  return <Box display="flex">{getLogo(tenant, brand, headerLogo)}</Box>;
};

export default Logo;
