import { Buffer } from 'buffer';

import React, { useEffect, useState, useRef, useMemo } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';

import ConfirmationUI from './components/ConfirmationUI';

export const isEmailMasked = (email: string): boolean => {
  const regex = /@privaterelay.appleid.com$/i;
  return regex.test(email);
};

const SigninConfirmation = () => {
  const { analytics } = useAnalyticsContext();
  const { hostConfig, language, locale } = useAppContext();
  const domainName = hostConfig.clientConfig.authDomain;
  const [redirectUrl, setRedirectUrl] = useState('');
  const emailRef = useRef('');
  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const isMobileClient = query.get('isMobileClient') === 'true';
  const referrer = query.get('referrer');
  useEffect(() => {
    const urlStateValue: string | null = query.get('state');
    const urlEmailValue: string | null = query.get('emailEnc');
    const fullLangLocale = `${language}-${locale?.toUpperCase()}`;
    const languageSuffix = isMobileClient
      ? `locale=${fullLangLocale}`
      : `language=${fullLangLocale}`;

    const userEmail: string = urlEmailValue
      ? Buffer.from(urlEmailValue, 'base64').toString('utf8')
      : 'your email';

    if (urlStateValue && userEmail) {
      emailRef.current = userEmail;

      const trackPageViewProps = {
        eventName: 'email_not_found_displayed',
        currentPage: 'signin confirmation',
        isEmailMasked: isEmailMasked(userEmail),
        ...(referrer && { identityProvider: referrer }),
      };
      analytics.trackPageView(trackPageViewProps);
      setRedirectUrl(
        `https://${domainName}/continue?state=${urlStateValue}&${languageSuffix}`,
      );
    }
  }, [
    analytics,
    domainName,
    language,
    locale,
    isMobileClient,
    query,
    referrer,
  ]);

  return redirectUrl ? (
    <ConfirmationUI
      redirectUrl={redirectUrl}
      email={emailRef.current}
      isMobileClient={isMobileClient}
    />
  ) : null;
};

export default SigninConfirmation;
