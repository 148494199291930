import React, { useEffect } from 'react';

import LoadingScreen from 'src/components/shared/LoadingScreen/LoadingScreen';
import getSearchParams from 'src/utils/getSearchParams';

import { changeLocation } from '../../../utils/changeLocation';

const Redirect = () => {
  useEffect(() => {
    const { returnPath } = getSearchParams();
    const destination = window.location.origin;

    changeLocation(returnPath.indexOf('/') === 0 ? returnPath : destination);
  });
  return <LoadingScreen />;
};

export default Redirect;
