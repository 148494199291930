import { EmployerHeader } from '@seek/adv-header-footer';
import type { ComponentProps } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import type { EmployerSiteName } from 'src/types';

const Header = () => {
  const { language, environment, locale, site } = useAppContext();
  const isStaging = environment !== 'production';
  const fullLocale = `${language}-${locale?.toUpperCase()}` as ComponentProps<
    typeof EmployerHeader
  >['locale'];

  return (
    <EmployerHeader
      locale={fullLocale}
      site={site as EmployerSiteName}
      isStaging={isStaging}
      basic={true}
    />
  );
};

export default Header;
