import qs from 'qs';

interface presetParams {
  email: string;
  msg: string;
  returnPath: string;
  returnUrl: string; // returnUrl is used instead of returnPath for candidate site
  realm: string;
  error_description: string;
}

export type SearchParams = presetParams & qs.ParsedQs;

const isValidRelativePath = (path: string) =>
  !path.startsWith('//') && !/^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//.test(path);

const getSearchParams = (search = window.location.search): SearchParams => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });

  return {
    ...params,
    email: typeof params.email === 'string' ? params.email : '',
    msg: typeof params.msg === 'string' ? params.msg : '',
    returnPath:
      typeof params.returnPath === 'string' &&
      isValidRelativePath(params.returnPath)
        ? params.returnPath
        : '/',
    returnUrl: typeof params.returnUrl === 'string' ? params.returnUrl : '',
    realm: typeof params.realm === 'string' ? params.realm : '',
    error_description:
      typeof params.error_description === 'string'
        ? params.error_description
        : '',
  };
};

export default getSearchParams;
