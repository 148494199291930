import { setup, metrics } from '@seek/metrics-js';
import { detect } from 'detect-browser';

import type { HookDomain, Hook } from 'src/modules/hooks/types';
import sleep from 'src/utils/sleep';

const prefix = 'online_identity_oauth_spa_v2';

const getHost = (environment: string) =>
  `https://identity-metrics${
    environment === 'production' ? '' : '.staging'
  }.cloud.seek.com.au`;

interface CreateOptions {
  environment: string;
  isEnabled?: boolean;
}

const toTags = (tags: Record<string, any>): string[] =>
  Object.keys(tags).map((key) => `${key}:${tags[key]}`);

export const setupMetrics = ({
  environment,
  isEnabled = true,
}: CreateOptions) => {
  const { name: browser, os } = detect() || {};
  const { hostname } = window.location;
  const globalTags = toTags({
    hostname,
    browser,
    os,
  });
  const host = getHost(environment);
  setup({ host, isEnabled, prefix, globalTags });
};

const trackDuration = (
  identifier: string,
  startTime: number,
  tags: string[],
) => {
  const duration = Date.now() - startTime;
  Promise.race([sleep(1000), metrics.timing(identifier, duration, tags)]);
};

export const count = (identifier: string, tags?: string[]) => {
  Promise.race([sleep(1000), metrics.count(identifier, tags)]);
};

export const pageHit = (pageName: string, tags: string[] = []) => {
  count('page_hit', [`page:${pageName}`, ...tags]);
};

export const trackHookDuration = (
  hook: Hook,
  hookDomain: HookDomain,
  startTime: number,
  hookResult: string | undefined,
) => {
  trackDuration('custom_hook', startTime, [
    `hookType:${hook}`,
    `hookDomain:${hookDomain}`,
    `hookResult:${hookResult}`,
  ]);
};
