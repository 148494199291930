import React, { useEffect } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import { useAuthN } from 'src/components/hooks/useAuthN';
import LoadingScreen from 'src/components/shared/LoadingScreen/LoadingScreen';
import getHook from 'src/modules/hooks/getHook';
import type { HookConfig, Hook } from 'src/modules/hooks/types';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import { trackHookDuration } from 'src/modules/tracking/metrics';
import type { AppLanguage } from 'src/types';
import getSearchParams from 'src/utils/getSearchParams';
import { handleError } from 'src/utils/handleError';

const HOOK_TYPE: Hook = 'pre-logout';

const Logout = () => {
  const { analytics } = useAnalyticsContext();
  const authN = useAuthN();
  const {
    hostConfig,
    renderConfig: { hookDomain },
    language,
    locale,
  } = useAppContext();

  useEffect(() => {
    const logout = async () => {
      try {
        const returnUrl = window.location.origin + getSearchParams().returnPath;

        const hook = await getHook(HOOK_TYPE, hookDomain);
        if (hook && !hostConfig.disableHooks) {
          const startTime = Date.now();
          const config: HookConfig = { authN, hostConfig, analytics };
          const shouldContinue = await hook(config, language, locale);
          trackHookDuration(
            HOOK_TYPE,
            hookDomain!,
            startTime,
            shouldContinue ? 'true' : 'false',
          );

          if (!shouldContinue) {
            return;
          }
        }

        await authN?.logout(returnUrl);
      } catch (e: any) {
        handleError(e.message, language as AppLanguage);
      }
    };

    logout();
  });

  return <LoadingScreen />;
};

export default Logout;
