import { Box, ContentBlock, Stack } from 'braid-design-system';
import React from 'react';

import ActiveSession from './components/ActiveSession';
import Config from './components/Config';
import LoginWithScope from './components/LoginWithScope';
import Title from './components/Title';

import './Debug.css';

const Debug = () => (
  <Box marginY="gutter">
    <ContentBlock width="large">
      <Stack space="gutter">
        <Title />
        <Config />
        <ActiveSession />
        <LoginWithScope />
      </Stack>
    </ContentBlock>
  </Box>
);

export default Debug;
