import { AuthN } from '@seek/online-identity';

import { useAppContext } from 'src/components/App/AppContext';
import { isBrowser } from 'src/utils/device';

let authN: AuthN | undefined;

const missingHostConfigErrMsg = () => {
  if (isBrowser()) {
    return `missing client config for hostname: ${window.location.hostname}`;
  }
  return `missing client config`;
};

export const useAuthN = () => {
  const { hostConfig } = useAppContext();
  if (!hostConfig) {
    const msg = missingHostConfigErrMsg();
    throw new Error(msg);
  }

  if (!isBrowser() || Boolean(authN)) {
    return authN!;
  }

  return new AuthN(hostConfig.clientConfig);
};
