import { Buffer } from 'buffer';

import React, { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';

import SecondFactorEnrolmentUI from '../../shared/SecondFactorEnrolment';

interface Props {
  mockWindow?: any;
}

/**
 * a blocking page for suspicious flow - redirected here via rules (Auth0-config)
 */
const SecondFactorEnrolment = ({ mockWindow }: Props) => {
  const { analytics } = useAnalyticsContext();
  const [email, setEmail] = useState<string>();
  const { boot } = useIntercom();

  useEffect(() => {
    const query = new URLSearchParams((mockWindow || window).location.search);
    const emailQueryStr: string | null = query.get('emailEnc');
    const userEmail = emailQueryStr
      ? Buffer.from(emailQueryStr, 'base64').toString('utf8')
      : 'email';

    const reason = query.get('reason');
    setEmail(userEmail);

    analytics.trackPageView({
      eventName: '2fa_enrolment_displayed',
      currentPage: '2fa enrolment',
      isLoginSuspicious: reason === 'suspicious',
      isUserBlocked: reason === 'block',
    });

    boot();
  }, [mockWindow, boot, analytics]);

  return <SecondFactorEnrolmentUI email={email} />;
};

export default SecondFactorEnrolment;
