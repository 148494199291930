import { useEffect } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';

import { changeLocation, getURLStateValue } from './utils';

const MfaChallengeAnalytics = () => {
  const { analytics } = useAnalyticsContext();
  const { hostConfig, language, locale } = useAppContext();
  const domainName = hostConfig.clientConfig.authDomain;

  useEffect(() => {
    const convertedLocale = locale ? locale.toUpperCase() : 'AU';
    const query = `${language}-${convertedLocale}`;

    const urlStateValue = getURLStateValue();
    if (urlStateValue !== null) {
      analytics.trackPageView({
        eventName: '2fa_enter_code_displayed',
        currentPage: '2fa enter code',
        isLoginSuspicious: true,
      });
      changeLocation(
        `https://${domainName}/continue?state=${urlStateValue}&language=${query}`,
      );
    }
  }, [analytics, domainName, language, locale]);
  return null;
};

export default MfaChallengeAnalytics;
