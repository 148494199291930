import { Box, ContentBlock } from 'braid-design-system';
import type React from 'react';

import * as styles from './MainLayout.css';

interface Props {
  children: React.ReactNode;
}

const MainLayout = ({ children }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="spaceBetween"
    className={styles.content}
    background="surface"
  >
    <ContentBlock>{children}</ContentBlock>
  </Box>
);

export default MainLayout;
