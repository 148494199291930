import type { AuthN } from '@seek/online-identity';
import type { IdTokenClaims } from '@seek/online-identity/lib-types/types';
import React, { useEffect, useState, useRef } from 'react';

import { useAuthN } from 'src/components/hooks/useAuthN';
import SecondFactorEnrolmentUI from 'src/components/shared/SecondFactorEnrolment';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import { handleError } from 'src/utils/handleError';
import { sendEnrolmentEmail } from 'src/utils/hirer/gql';

export const enrolmentEmailToUser = async (authN: AuthN): Promise<string> => {
  const authenticated: boolean = await authN.isAuthenticated();
  if (authenticated) {
    const token = await authN.getToken();
    if (token) {
      const value: IdTokenClaims = await authN.getUser();
      const email = value.email;
      await sendEnrolmentEmail(token);
      return email;
    }
    throw new Error('no token');
  } else {
    throw new Error('not authenticated');
  }
};

/**
 * a non-blocking page for non-suspicious flow - redirected here via intercom
 */
const SecondFactorEnrolmentOptIn = () => {
  const { analytics } = useAnalyticsContext();
  const authN: AuthN | undefined = useAuthN();
  const [email, setEmail] = useState('');
  const isEmailSet = useRef(false);

  useEffect(() => {
    const updateEnrollmentEmail = async () => {
      enrolmentEmailToUser(authN)
        .then((emailValue) => {
          setEmail(emailValue);
          analytics.trackPageView({
            eventName: '2fa_enrolment_displayed',
            currentPage: '2fa enrolment - opt in',
            isLoginSuspicious: false,
            isUserBlocked: false,
          });
        })
        .catch((e: any) => {
          handleError(e);
        })
        .finally(() => {
          isEmailSet.current = true;
        });
    };
    if (!isEmailSet.current) {
      updateEnrollmentEmail();
    }
  }, [authN, analytics]);

  return email ? (
    <SecondFactorEnrolmentUI email={email} differentAccountLink={false} />
  ) : null;
};

export default SecondFactorEnrolmentOptIn;
