
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50Ijoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4Lil4LiH4LiX4Liw4LmA4Lia4Li14Lii4LiZ4Liq4Liz4Lir4Lij4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJ4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIldlIGRpZG4ndCBmaW5kIGFjY291bnQgZm9yIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIHticmFuZH0g4Liq4Liz4Lir4Lij4Lix4Lia4Lit4Li14LmA4Lih4LilIiwiUmVnaXN0ZXIgbmV3IGFjY291bnQiOiLguKXguIfguJfguLDguYDguJrguLXguKLguJnguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguYPguKvguKHguYgiLCJCYWNrIHRvIHNpZ24gaW4iOiLguIHguKXguLHguJrguYTguJvguJfguLXguYjguYDguILguYnguLLguKrguLnguYjguKPguLDguJrguJoiLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IuC4quC4s+C4q+C4o+C4seC4muC4geC4suC4o+C4quC4o+C5ieC4suC4h+C4muC4seC4jeC4iuC4tSDguITguLjguJPguJXguYnguK3guIfguKLguK3guKHguKPguLHguJrguILguYnguK3guIHguLPguKvguJnguJTguILguYnguLLguIfguJXguYnguJkiLCJCeSByZWdpc3RlcmluZyB5b3UgYWdyZWUgdG8gU0VFS+KAmXMgUHJpdmFjeSBQb2xpY3kiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms+UHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50Ijoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4Lil4LiH4LiX4Liw4LmA4Lia4Li14Lii4LiZ4Liq4Liz4Lir4Lij4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJ4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIldlIGRpZG4ndCBmaW5kIGFjY291bnQgZm9yIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIHticmFuZH0g4Liq4Liz4Lir4Lij4Lix4Lia4Lit4Li14LmA4Lih4LilIiwiUmVnaXN0ZXIgbmV3IGFjY291bnQiOiLguKXguIfguJfguLDguYDguJrguLXguKLguJnguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguYPguKvguKHguYgiLCJCYWNrIHRvIHNpZ24gaW4iOiLguIHguKXguLHguJrguYTguJvguJfguLXguYjguYDguILguYnguLLguKrguLnguYjguKPguLDguJrguJoiLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IuC4quC4s+C4q+C4o+C4seC4muC4geC4suC4o+C4quC4o+C5ieC4suC4h+C4muC4seC4jeC4iuC4tSDguITguLjguJPguJXguYnguK3guIfguKLguK3guKHguKPguLHguJrguILguYnguK3guIHguLPguKvguJnguJTguILguYnguLLguIfguJXguYnguJkiLCJCeSByZWdpc3RlcmluZyB5b3UgYWdyZWUgdG8gU0VFS+KAmXMgUHJpdmFjeSBQb2xpY3kiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms+UHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiQnVhdCBha3VuIGJhcnU/IiwiV2UgZGlkbid0IGZpbmQgYWNjb3VudCBmb3IiOiJLYW1pIHRpZGFrIG1lbmVtdWthbiBha3VuIHticmFuZH0gdW50dWsiLCJSZWdpc3RlciBuZXcgYWNjb3VudCI6IkJ1YXQgYWt1biBiYXJ1IiwiQmFjayB0byBzaWduIGluIjoiS2VtYmFsaSBrZSBoYWxhbWFuIG1hc3VrIiwiVG8gY3JlYXRlIGFuIGFjY291bnQsIHlvdSBtdXN0IGFncmVlIHRvIHRoZSBhYm92ZSB0ZXJtcy4iOiJVbnR1ayBtZW1idWF0IGFrdW4sIEFuZGEgaGFydXMgbWVueWV0dWp1aSBrZXRlbnR1YW4gZGkgYXRhcyIsIkJ5IHJlZ2lzdGVyaW5nIHlvdSBhZ3JlZSB0byBTRUVL4oCZcyBQcml2YWN5IFBvbGljeSI6IkJ5IHJlZ2lzdGVyaW5nLCBJIGFncmVlIHRvIFNFRUvigJlzIDxQcml2YWN5UG9saWN5TGluaz5Qcml2YWN5IFBvbGljeTwvUHJpdmFjeVBvbGljeUxpbms+In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiQnVhdCBha3VuIGJhcnU/IiwiV2UgZGlkbid0IGZpbmQgYWNjb3VudCBmb3IiOiJLYW1pIHRpZGFrIG1lbmVtdWthbiBha3VuIHticmFuZH0gdW50dWsiLCJSZWdpc3RlciBuZXcgYWNjb3VudCI6IkJ1YXQgYWt1biBiYXJ1IiwiQmFjayB0byBzaWduIGluIjoiS2VtYmFsaSBrZSBoYWxhbWFuIG1hc3VrIiwiVG8gY3JlYXRlIGFuIGFjY291bnQsIHlvdSBtdXN0IGFncmVlIHRvIHRoZSBhYm92ZSB0ZXJtcy4iOiJVbnR1ayBtZW1idWF0IGFrdW4sIEFuZGEgaGFydXMgbWVueWV0dWp1aSBrZXRlbnR1YW4gZGkgYXRhcyIsIkJ5IHJlZ2lzdGVyaW5nIHlvdSBhZ3JlZSB0byBTRUVL4oCZcyBQcml2YWN5IFBvbGljeSI6IkJ5IHJlZ2lzdGVyaW5nLCBJIGFncmVlIHRvIFNFRUvigJlzIDxQcml2YWN5UG9saWN5TGluaz5Qcml2YWN5IFBvbGljeTwvUHJpdmFjeVBvbGljeUxpbms+In0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50Ijoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4Lil4LiH4LiX4Liw4LmA4Lia4Li14Lii4LiZ4Liq4Liz4Lir4Lij4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJ4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIldlIGRpZG4ndCBmaW5kIGFjY291bnQgZm9yIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIHticmFuZH0g4Liq4Liz4Lir4Lij4Lix4Lia4Lit4Li14LmA4Lih4LilIiwiUmVnaXN0ZXIgbmV3IGFjY291bnQiOiLguKXguIfguJfguLDguYDguJrguLXguKLguJnguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguYPguKvguKHguYgiLCJCYWNrIHRvIHNpZ24gaW4iOiLguIHguKXguLHguJrguYTguJvguJfguLXguYjguYDguILguYnguLLguKrguLnguYjguKPguLDguJrguJoiLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IuC4quC4s+C4q+C4o+C4seC4muC4geC4suC4o+C4quC4o+C5ieC4suC4h+C4muC4seC4jeC4iuC4tSDguITguLjguJPguJXguYnguK3guIfguKLguK3guKHguKPguLHguJrguILguYnguK3guIHguLPguKvguJnguJTguILguYnguLLguIfguJXguYnguJkiLCJCeSByZWdpc3RlcmluZyB5b3UgYWdyZWUgdG8gU0VFS+KAmXMgUHJpdmFjeSBQb2xpY3kiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms+UHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50Ijoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4Lil4LiH4LiX4Liw4LmA4Lia4Li14Lii4LiZ4Liq4Liz4Lir4Lij4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJ4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIldlIGRpZG4ndCBmaW5kIGFjY291bnQgZm9yIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIHticmFuZH0g4Liq4Liz4Lir4Lij4Lix4Lia4Lit4Li14LmA4Lih4LilIiwiUmVnaXN0ZXIgbmV3IGFjY291bnQiOiLguKXguIfguJfguLDguYDguJrguLXguKLguJnguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguYPguKvguKHguYgiLCJCYWNrIHRvIHNpZ24gaW4iOiLguIHguKXguLHguJrguYTguJvguJfguLXguYjguYDguILguYnguLLguKrguLnguYjguKPguLDguJrguJoiLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IuC4quC4s+C4q+C4o+C4seC4muC4geC4suC4o+C4quC4o+C5ieC4suC4h+C4muC4seC4jeC4iuC4tSDguITguLjguJPguJXguYnguK3guIfguKLguK3guKHguKPguLHguJrguILguYnguK3guIHguLPguKvguJnguJTguILguYnguLLguIfguJXguYnguJkiLCJCeSByZWdpc3RlcmluZyB5b3UgYWdyZWUgdG8gU0VFS+KAmXMgUHJpdmFjeSBQb2xpY3kiOiJCeSByZWdpc3RlcmluZywgSSBhZ3JlZSB0byBTRUVL4oCZcyA8UHJpdmFjeVBvbGljeUxpbms+UHJpdmFjeSBQb2xpY3k8L1ByaXZhY3lQb2xpY3lMaW5rPiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiQnVhdCBha3VuIGJhcnU/IiwiV2UgZGlkbid0IGZpbmQgYWNjb3VudCBmb3IiOiJLYW1pIHRpZGFrIG1lbmVtdWthbiBha3VuIHticmFuZH0gdW50dWsiLCJSZWdpc3RlciBuZXcgYWNjb3VudCI6IkJ1YXQgYWt1biBiYXJ1IiwiQmFjayB0byBzaWduIGluIjoiS2VtYmFsaSBrZSBoYWxhbWFuIG1hc3VrIiwiVG8gY3JlYXRlIGFuIGFjY291bnQsIHlvdSBtdXN0IGFncmVlIHRvIHRoZSBhYm92ZSB0ZXJtcy4iOiJVbnR1ayBtZW1idWF0IGFrdW4sIEFuZGEgaGFydXMgbWVueWV0dWp1aSBrZXRlbnR1YW4gZGkgYXRhcyIsIkJ5IHJlZ2lzdGVyaW5nIHlvdSBhZ3JlZSB0byBTRUVL4oCZcyBQcml2YWN5IFBvbGljeSI6IkJ5IHJlZ2lzdGVyaW5nLCBJIGFncmVlIHRvIFNFRUvigJlzIDxQcml2YWN5UG9saWN5TGluaz5Qcml2YWN5IFBvbGljeTwvUHJpdmFjeVBvbGljeUxpbms+In0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiQnVhdCBha3VuIGJhcnU/IiwiV2UgZGlkbid0IGZpbmQgYWNjb3VudCBmb3IiOiJLYW1pIHRpZGFrIG1lbmVtdWthbiBha3VuIHticmFuZH0gdW50dWsiLCJSZWdpc3RlciBuZXcgYWNjb3VudCI6IkJ1YXQgYWt1biBiYXJ1IiwiQmFjayB0byBzaWduIGluIjoiS2VtYmFsaSBrZSBoYWxhbWFuIG1hc3VrIiwiVG8gY3JlYXRlIGFuIGFjY291bnQsIHlvdSBtdXN0IGFncmVlIHRvIHRoZSBhYm92ZSB0ZXJtcy4iOiJVbnR1ayBtZW1idWF0IGFrdW4sIEFuZGEgaGFydXMgbWVueWV0dWp1aSBrZXRlbnR1YW4gZGkgYXRhcyIsIkJ5IHJlZ2lzdGVyaW5nIHlvdSBhZ3JlZSB0byBTRUVL4oCZcyBQcml2YWN5IFBvbGljeSI6IkJ5IHJlZ2lzdGVyaW5nLCBJIGFncmVlIHRvIFNFRUvigJlzIDxQcml2YWN5UG9saWN5TGluaz5Qcml2YWN5IFBvbGljeTwvUHJpdmFjeVBvbGljeUxpbms+In0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgYSBuZXcgYWNjb3VudD8iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IldlIGRpZG4ndCBmaW5kIGEge2JyYW5kfSBhY2NvdW50IGZvciIsIlJlZ2lzdGVyIG5ldyBhY2NvdW50IjoiUmVnaXN0ZXIgbmV3IGFjY291bnQiLCJCYWNrIHRvIHNpZ24gaW4iOiJCYWNrIHRvIHNpZ24gaW4iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6IlRvIGNyZWF0ZSBhbiBhY2NvdW50LCB5b3UgbXVzdCBhZ3JlZSB0byB0aGUgYWJvdmUgdGVybXMuIiwiQnkgcmVnaXN0ZXJpbmcgeW91IGFncmVlIHRvIFNFRUvigJlzIFByaXZhY3kgUG9saWN5IjoiQnkgcmVnaXN0ZXJpbmcsIEkgYWdyZWUgdG8gU0VFS+KAmXMgPFByaXZhY3lQb2xpY3lMaW5rPlByaXZhY3kgUG9saWN5PC9Qcml2YWN5UG9saWN5TGluaz4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiW8WY4bq94bq94bq9xKPDrMOsw6zFoeG5r+G6veG6veG6vcWZIMSDxIPEgyDguIHguLXguYnhur3hur3hur3FtSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvP10iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IlvFtOG6veG6veG6vSDGjMOsw6zDrMaM4LiB4Li14LmJJ+G5ryDGksOsw6zDrOC4geC4teC5icaMIMSDxIPEgyB7YnJhbmR9IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8gxpLDtsO2w7bFmV0iLCJSZWdpc3RlciBuZXcgYWNjb3VudCI6IlvFmOG6veG6veG6vcSjw6zDrMOsxaHhua/hur3hur3hur3FmSDguIHguLXguYnhur3hur3hur3FtSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvXSIsIkJhY2sgdG8gc2lnbiBpbiI6Ilvhup7Eg8SDxIPDp8S3IOG5r8O2w7bDtiDFocOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iV0iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6Ilvhua7DtsO2w7Ygw6fFmeG6veG6veG6vcSDxIPEg+G5r+G6veG6veG6vSDEg8SDxIPguIHguLXguYkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5rywgw73DvcO9w7bDtsO2x5rHmseaIG3Mgseax5rHmsWh4bmvIMSDxIPEg8SjxZnhur3hur3hur3hur3hur3hur0g4bmvw7bDtsO2IOG5r+G4qeG6veG6veG6vSDEg8SDxIPDn8O2w7bDtuG5veG6veG6veG6vSDhua/hur3hur3hur3FmW3MgsWhLl0iLCJCeSByZWdpc3RlcmluZyB5b3UgYWdyZWUgdG8gU0VFS+KAmXMgUHJpdmFjeSBQb2xpY3kiOiJb4bqew73DvcO9IMWZ4bq94bq94bq9xKPDrMOsw6zFoeG5r+G6veG6veG6vcWZw6zDrMOs4LiB4Li14LmJxKMsIMOPIMSDxIPEg8SjxZnhur3hur3hur3hur3hur3hur0g4bmvw7bDtsO2IMWgw4vDi+G4sOKAmcWhIDxQcml2YWN5UG9saWN5TGluaz7GpMWZw6zDrOG5vcSDxIPDp8O9w70gxqTDtsO2xprDrMOsw6fDvcO9PC9Qcml2YWN5UG9saWN5TGluaz5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWdpc3RlciBhIG5ldyBhY2NvdW50IjoiW8WY4bq94bq94bq9xKPDrMOsw6zFoeG5r+G6veG6veG6vcWZIMSDxIPEgyDguIHguLXguYnhur3hur3hur3FtSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvP10iLCJXZSBkaWRuJ3QgZmluZCBhY2NvdW50IGZvciI6IlvFtOG6veG6veG6vSDGjMOsw6zDrMaM4LiB4Li14LmJJ+G5ryDGksOsw6zDrOC4geC4teC5icaMIMSDxIPEgyB7YnJhbmR9IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8gxpLDtsO2w7bFmV0iLCJSZWdpc3RlciBuZXcgYWNjb3VudCI6IlvFmOG6veG6veG6vcSjw6zDrMOsxaHhua/hur3hur3hur3FmSDguIHguLXguYnhur3hur3hur3FtSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvXSIsIkJhY2sgdG8gc2lnbiBpbiI6Ilvhup7Eg8SDxIPDp8S3IOG5r8O2w7bDtiDFocOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iV0iLCJUbyBjcmVhdGUgYW4gYWNjb3VudCwgeW91IG11c3QgYWdyZWUgdG8gdGhlIGFib3ZlIHRlcm1zLiI6Ilvhua7DtsO2w7Ygw6fFmeG6veG6veG6vcSDxIPEg+G5r+G6veG6veG6vSDEg8SDxIPguIHguLXguYkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5rywgw73DvcO9w7bDtsO2x5rHmseaIG3Mgseax5rHmsWh4bmvIMSDxIPEg8SjxZnhur3hur3hur3hur3hur3hur0g4bmvw7bDtsO2IOG5r+G4qeG6veG6veG6vSDEg8SDxIPDn8O2w7bDtuG5veG6veG6veG6vSDhua/hur3hur3hur3FmW3MgsWhLl0iLCJCeSByZWdpc3RlcmluZyB5b3UgYWdyZWUgdG8gU0VFS+KAmXMgUHJpdmFjeSBQb2xpY3kiOiJb4bqew73DvcO9IMWZ4bq94bq94bq9xKPDrMOsw6zFoeG5r+G6veG6veG6vcWZw6zDrMOs4LiB4Li14LmJxKMsIMOPIMSDxIPEg8SjxZnhur3hur3hur3hur3hur3hur0g4bmvw7bDtsO2IMWgw4vDi+G4sOKAmcWhIDxQcml2YWN5UG9saWN5TGluaz7GpMWZw6zDrOG5vcSDxIPDp8O9w70gxqTDtsO2xprDrMOsw6fDvcO9PC9Qcml2YWN5UG9saWN5TGluaz5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    