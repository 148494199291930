import { oauth } from '@seek/oauth-url-builders';
import { ScopeHandler } from '@seek/online-identity';
import type { Realm } from '@seek/online-identity/lib-types/types';
import {
  TextLinkButton,
  Card,
  Stack,
  TextField,
  Button,
  Inline,
  RadioGroup,
  RadioItem,
  Heading,
} from 'braid-design-system';
import React, { type FormEvent, useEffect, useState } from 'react';

import { useAuthN } from 'src/components/hooks/useAuthN';
import { handleError } from 'src/utils/handleError';

const getInitialScope = (): string => {
  const scopeHandler = new ScopeHandler();
  return scopeHandler.scope;
};

const allowedRealms: Realm[] = [
  'seek-okta-global',
  'Username-Password-Authentication',
];

const getLoginWithScopeUrl = ({
  rawScope,
  realm,
  returnUri,
}: {
  rawScope: string;
  realm: Realm;
  returnUri: string;
}) => {
  const scope = rawScope.split(' ');
  return oauth.loginWithScopeUrl({
    scope,
    realm,
    returnUri,
  });
};

const LoginWithScope = () => {
  const authN = useAuthN();
  const [rawScope, setScope] = useState(getInitialScope());
  // const [loginWithScopeUrl, setLoginWithScopeUrl] = useState();
  const [returnUri, setReturnUri] = useState('/');
  const [realm, setRealm] = useState<Realm>('seek-okta-global');

  const [loginWithScopeUrl, setLoginWithScopeUrl] = useState('');

  useEffect(() => {
    const fn = async () => {
      try {
        const user = await authN.getUser();
        setRealm(
          user.sub.startsWith('auth0|')
            ? 'Username-Password-Authentication'
            : 'seek-okta-global',
        );
      } catch (err) {
        // ignore/suppress login-required errors
      }
    };
    fn();
  }, [authN]);

  useEffect(() => {
    const url = getLoginWithScopeUrl({
      rawScope,
      realm,
      returnUri,
    });
    setLoginWithScopeUrl(url);
  }, [rawScope, realm, returnUri]);

  const loginWithScope = (e: FormEvent) => {
    e.preventDefault();
    try {
      const url = loginWithScopeUrl;
      // eslint-disable-next-line no-console
      console.log({ url });
      window.location.href = url;
    } catch (err: any) {
      handleError(err);
    }
  };

  const handleScopeChange = (event: any) => {
    setScope(event.target.value);
  };

  const [copyToggle, setCopyToggle] = useState(false);
  const copyUri2Clipboard = () => {
    // create absolute url as it is paste-friendly for slack etc
    const absoluteUrl = window.location.origin + loginWithScopeUrl;

    navigator.clipboard.writeText(absoluteUrl);
    setCopyToggle(true);
    setTimeout(() => {
      setCopyToggle(false);
    }, 3000);
  };

  return (
    <Card>
      <form onSubmit={loginWithScope}>
        <Stack space="medium">
          <Heading level="3">Login with Scope</Heading>
          <RadioGroup
            id="realm"
            value={realm}
            description="Privileged scopes will only work with seek-okta-global connection"
            onChange={({ currentTarget: { value } }) =>
              setRealm(value as Realm)
            }
            label="Realm"
          >
            {allowedRealms.map((item) => (
              <RadioItem key={item} label={item} value={item} />
            ))}
          </RadioGroup>
          <TextField
            label="Return url"
            id="return_uri"
            description={
              <>
                Relative path (and optional querystring) to be redirected after
                signing in.{' '}
                <TextLinkButton
                  onClick={() => setReturnUri(window.location.pathname)}
                >
                  Use debug page.
                </TextLinkButton>
              </>
            }
            value={returnUri}
            onChange={({ currentTarget: { value } }) => setReturnUri(value)}
          />
          <TextField
            label="Scope"
            id="scope"
            description="Space separated list of scope values ie 'advertiser:28899855 key2:value2 key3'"
            value={rawScope}
            onChange={handleScopeChange}
          />
          <TextField
            label="Result"
            id="result"
            value={loginWithScopeUrl}
            onChange={() => {}}
            disabled
          />
          <Inline space="small" collapseBelow="desktop">
            <Button type="submit">Login</Button>
            <Button variant="soft" onClick={() => copyUri2Clipboard()}>
              {copyToggle ? 'url copied! 😀' : 'Copy to clipboard'}
            </Button>
          </Inline>
        </Stack>
      </form>
    </Card>
  );
};

export default LoginWithScope;
