import { BraidProvider, makeLinkComponent } from 'braid-design-system';
import React, { type ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { BRAID_THEMES, type BraidThemeName } from 'src/types';

const CustomLink = makeLinkComponent(({ href, ...restProps }, ref) => {
  if (/^https?:\/\//.test(href)) {
    return <a ref={ref} href={href} {...restProps} />;
  }

  return <ReactRouterLink ref={ref} to={href} {...restProps} />;
});

interface ProviderProps {
  braidThemeName: BraidThemeName;
  children: ReactNode;
}

export const BraidDesignProvider = ({
  braidThemeName,
  children,
}: ProviderProps) => (
  <BraidProvider
    theme={BRAID_THEMES[braidThemeName]}
    linkComponent={CustomLink}
  >
    {children}
  </BraidProvider>
);
