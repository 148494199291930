import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Callback from 'src/components/pages/Callback/Callback';
import Debug from 'src/components/pages/Debug/Debug';
import EmailLookup from 'src/components/pages/EmailLookup/EmailLookup';
import Error from 'src/components/pages/Error/Error';
import ForgotPassword from 'src/components/pages/ForgotPassword/ForgotPassword';
import Integration from 'src/components/pages/Integration/Integration';
import Login from 'src/components/pages/Login/Login';
import Logout from 'src/components/pages/Logout/Logout';
import MfaChallengeAnalytics from 'src/components/pages/MfaChallengeAnalytics/MfaChallengeAnalytics';
import Register from 'src/components/pages/Register/Register';
import SecondFactorEnrolment from 'src/components/pages/SecondFactorEnrolment/SecondFactorEnrolment';
import SecondFactorEnrolmentOptIn from 'src/components/pages/SecondFactorEnrolmentOptIn/SecondFactorEnrolmentOptIn';
import SigninConfirmation from 'src/components/pages/Social/SigninConfirmation';
import Ticket from 'src/components/pages/Ticket/Ticket';
import {
  type SiteName,
  localizeRoutesForSite,
  AppRoute,
} from 'src/modules/configuration';
import { AnalyticsProvider } from 'src/modules/tracking/analytics/AnalyticsContext';

import Redirect from '../pages/Redirect/Redirect';

import PageTracker from './PageTracker';

const ROUTE_COMPONENT = {
  [AppRoute.Debug]: Debug,
  [AppRoute.Login]: Login,
  [AppRoute.Logout]: Logout,
  [AppRoute.Callback]: Callback,
  [AppRoute.Integration]: Integration,
  [AppRoute.ForgotPassword]: ForgotPassword,
  [AppRoute.Register]: Register,
  [AppRoute.EmailLookup]: EmailLookup,
  [AppRoute.Error]: Error,
  [AppRoute.Redirect]: Redirect,
  [AppRoute.SecondFactorEnrolment]: SecondFactorEnrolment,
  [AppRoute.MfaChallengeAnalytics]: MfaChallengeAnalytics,
  [AppRoute.SecondFactorEnrolmentOptIn]: SecondFactorEnrolmentOptIn,
  [AppRoute.Ticket]: Ticket,
  [AppRoute.SigninConfirmation]: SigninConfirmation,
} as const;

interface Props {
  site: SiteName;
}

export const AppRouter = ({ site }: Props) => {
  const routes = localizeRoutesForSite(site);

  return (
    <Routes>
      {routes.map(({ name, route }) => (
        <Route
          key={name}
          path={route}
          element={
            <AnalyticsProvider>
              <PageTracker path={route} Component={ROUTE_COMPONENT[name]} />
            </AnalyticsProvider>
          }
        />
      ))}
    </Routes>
  );
};
