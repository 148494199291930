import { adapter } from '@seek/tealium-adapter';

import type {
  Analytics,
  Adapter,
  TrackLinkProps,
  TrackPageViewProps,
} from './types';

const createAnalytics = (globalProps: any): Analytics => {
  const { link, view }: Adapter = adapter({});

  return {
    trackPageView: (eventProps: TrackPageViewProps): void => {
      view(eventProps.currentPage, {
        ...globalProps,
        ...eventProps,
      });
    },
    trackLink: (eventProps: TrackLinkProps): void => {
      link(eventProps.eventName, {
        ...globalProps,
        ...eventProps,
      });
    },
  };
};

export default createAnalytics;
