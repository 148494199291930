import { Card, Heading, Text, Stack } from 'braid-design-system';
import React from 'react';

import { useAuthN } from 'src/components/hooks/useAuthN';

const Config = () => {
  const authN = useAuthN();
  const config = authN ? JSON.stringify(authN.config, undefined, 2) : '';

  return (
    <Card>
      <Stack space="small">
        <Heading level="3">Configuration</Heading>
        <Text>This is the configuration that is supplied for your domain.</Text>
        <Text size="small">
          <pre>{config}</pre>
        </Text>
      </Stack>
    </Card>
  );
};

export default Config;
