import type { Language } from '@seek/melways-sites';

import type { AppLanguage } from 'src/types';
export const defaultLanguage: AppLanguage = 'en';
export const additionalLanguages = ['th', 'id'];

/**
 * Maps Melways languages to the languages supported by this app.
 */
export const supportedLanguages: Record<Language, AppLanguage> = {
  en: 'en',
  id: 'id',
  th: 'th',
  // zh will fallback to english. This is a temporary until all SEEK packages support zh.
  zh: 'en',
};

export const getLanguagePrefixedPath = (
  language: AppLanguage,
  basePath: string = '',
): string => (language === 'en' ? basePath : `/${language}${basePath}`);
