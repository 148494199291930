import React from 'react';

import Login from '../Login/Login';

const Register = () => (
  <>
    <Login path="/register" />
  </>
);

export default Register;
