import {
  talent_staging_seek_co_nz,
  talent_staging_seek_com_au,
  ph_employer_staging_seek_com,
  sg_employer_staging_seek_com,
  id_employer_staging_seek_com,
  my_employer_staging_seek_com,
  th_employer_staging_seek_com,
  hk_employer_staging_seek_com,
} from '@seek/online-identity';

import type { RenderConfig } from '../../types';

import dev from './development';

export default {
  hostConfigs: {
    ...dev.hostConfigs,
    [talent_staging_seek_com_au.clientDomain]: {
      clientConfig: talent_staging_seek_com_au,
      environment: {},
    },
    [talent_staging_seek_co_nz.clientDomain]: {
      clientConfig: talent_staging_seek_co_nz,
      environment: {},
    },
    [ph_employer_staging_seek_com.clientDomain]: {
      clientConfig: ph_employer_staging_seek_com,
      environment: {},
    },
    [sg_employer_staging_seek_com.clientDomain]: {
      clientConfig: sg_employer_staging_seek_com,
      environment: {},
    },
    [th_employer_staging_seek_com.clientDomain]: {
      clientConfig: th_employer_staging_seek_com,
      environment: {},
    },
    [hk_employer_staging_seek_com.clientDomain]: {
      clientConfig: hk_employer_staging_seek_com,
      environment: {},
    },
    [id_employer_staging_seek_com.clientDomain]: {
      clientConfig: id_employer_staging_seek_com,
      environment: {},
    },
    [my_employer_staging_seek_com.clientDomain]: {
      clientConfig: my_employer_staging_seek_com,
      environment: {},
    },
  },
  hookDomain: 'hirer',
  themeName: 'seekJobs',
} as RenderConfig;
