
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoi4Lij4Lix4LiB4Lip4Liy4LiE4Lin4Liy4Lih4Lib4Lil4Lit4LiU4Lig4Lix4Lii4Lia4Lix4LiN4LiK4Li1IFNFRUsg4LiC4Lit4LiH4LiE4Li44LiT4LmC4LiU4Lii4LiB4Liy4Lij4LiV4Lix4LmJ4LiH4LiE4LmI4Liy4LiB4Liy4Lij4Lii4Li34LiZ4Lii4Lix4LiZ4LiV4Lix4Lin4LiV4LiZ4LmC4LiU4Lii4LmD4LiK4LmJ4Lir4Lil4Liy4Lii4Lib4Lix4LiI4LiI4Lix4LiiIiwiQ29udGludWUiOiLguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoi4Lij4Lix4LiB4Lip4Liy4LiE4Lin4Liy4Lih4Lib4Lil4Lit4LiU4Lig4Lix4Lii4Lia4Lix4LiN4LiK4Li1IFNFRUsg4LiC4Lit4LiH4LiE4Li44LiT4LmC4LiU4Lii4LiB4Liy4Lij4LiV4Lix4LmJ4LiH4LiE4LmI4Liy4LiB4Liy4Lij4Lii4Li34LiZ4Lii4Lix4LiZ4LiV4Lix4Lin4LiV4LiZ4LmC4LiU4Lii4LmD4LiK4LmJ4Lir4Lil4Liy4Lii4Lib4Lix4LiI4LiI4Lix4LiiIiwiQ29udGludWUiOiLguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK0ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiQW1hbmthbiBha3VuIFNFRUsgQW5kYSBkZW5nYW4gbWVuZ2F0dXIgYXV0ZW50aWthc2kgbXVsdGlmYWt0b3IiLCJDb250aW51ZSI6Ikxhbmp1dGthbiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiQW1hbmthbiBha3VuIFNFRUsgQW5kYSBkZW5nYW4gbWVuZ2F0dXIgYXV0ZW50aWthc2kgbXVsdGlmYWt0b3IiLCJDb250aW51ZSI6Ikxhbmp1dGthbiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoi4Lij4Lix4LiB4Lip4Liy4LiE4Lin4Liy4Lih4Lib4Lil4Lit4LiU4Lig4Lix4Lii4Lia4Lix4LiN4LiK4Li1IFNFRUsg4LiC4Lit4LiH4LiE4Li44LiT4LmC4LiU4Lii4LiB4Liy4Lij4LiV4Lix4LmJ4LiH4LiE4LmI4Liy4LiB4Liy4Lij4Lii4Li34LiZ4Lii4Lix4LiZ4LiV4Lix4Lin4LiV4LiZ4LmC4LiU4Lii4LmD4LiK4LmJ4Lir4Lil4Liy4Lii4Lib4Lix4LiI4LiI4Lix4LiiIiwiQ29udGludWUiOiLguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoi4Lij4Lix4LiB4Lip4Liy4LiE4Lin4Liy4Lih4Lib4Lil4Lit4LiU4Lig4Lix4Lii4Lia4Lix4LiN4LiK4Li1IFNFRUsg4LiC4Lit4LiH4LiE4Li44LiT4LmC4LiU4Lii4LiB4Liy4Lij4LiV4Lix4LmJ4LiH4LiE4LmI4Liy4LiB4Liy4Lij4Lii4Li34LiZ4Lii4Lix4LiZ4LiV4Lix4Lin4LiV4LiZ4LmC4LiU4Lii4LmD4LiK4LmJ4Lir4Lil4Liy4Lii4Lib4Lix4LiI4LiI4Lix4LiiIiwiQ29udGludWUiOiLguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK0ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiQW1hbmthbiBha3VuIFNFRUsgQW5kYSBkZW5nYW4gbWVuZ2F0dXIgYXV0ZW50aWthc2kgbXVsdGlmYWt0b3IiLCJDb250aW51ZSI6Ikxhbmp1dGthbiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiQW1hbmthbiBha3VuIFNFRUsgQW5kYSBkZW5nYW4gbWVuZ2F0dXIgYXV0ZW50aWthc2kgbXVsdGlmYWt0b3IiLCJDb250aW51ZSI6Ikxhbmp1dGthbiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiU2VjdXJlIHlvdXIgU0VFSyBhY2NvdW50IGJ5IHNldHRpbmcgdXAgbXVsdGktZmFjdG9yIGF1dGhlbnRpY2F0aW9uIiwiQ29udGludWUiOiJDb250aW51ZSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiW8Wg4bq94bq94bq9w6fHmseax5rFmeG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDFoMOLw4vhuLAgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDDn8O9w73DvSDFoeG6veG6veG6veG5r+G5r8Osw6zDrOC4geC4teC5icSjIMeax5rHmsalIG3Mgseax5rHmsaa4bmvw6zDrMOsLcaSxIPEg8SDw6fhua/DtsO2w7bFmSDEg8SDxIPHmseax5rhua/huKnhur3hur3hur3guIHguLXguYnhua/DrMOsw6zDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0iLCJDb250aW51ZSI6IlvDh8O2w7bguIHguLXguYnhua/DrMOs4LiB4Li14LmJx5rHmuG6veG6vV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWN1cmUgeW91ciBTRUVLIGFjY291bnQgYnkgTUZBIjoiW8Wg4bq94bq94bq9w6fHmseax5rFmeG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDFoMOLw4vhuLAgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDDn8O9w73DvSDFoeG6veG6veG6veG5r+G5r8Osw6zDrOC4geC4teC5icSjIMeax5rHmsalIG3Mgseax5rHmsaa4bmvw6zDrMOsLcaSxIPEg8SDw6fhua/DtsO2w7bFmSDEg8SDxIPHmseax5rhua/huKnhur3hur3hur3guIHguLXguYnhua/DrMOsw6zDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0iLCJDb250aW51ZSI6IlvDh8O2w7bguIHguLXguYnhua/DrMOs4LiB4Li14LmJx5rHmuG6veG6vV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    