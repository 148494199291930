import { createForm } from '@seek/forms-ui';
import { createValidator } from '@seek/validators-js';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Card,
  ContentBlock,
  Text,
  Stack,
  Heading,
  ButtonLink,
  Button,
  Strong,
  TextLink,
  useResponsiveValue,
} from 'braid-design-system';
import React from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import CandidateHeader from 'src/components/shared/Header/CandidateHeader';
import { brandName } from 'src/utils/brandName';

import { changeLocation } from '../../../../utils/changeLocation';
import getClientHrefLink from '../../../../utils/getClientHrefLink';
import translations from '../.vocab';

import ConsentCheckbox from './ConsentCheckbox/ConsentCheckbox';

import * as styles from './ConfirmationUI.css';

interface FormValues {
  privacyAccepted: boolean;
}

const { FormProvider, useField } = createForm<FormValues>();

interface ConfirmationUIProps {
  redirectUrl: string;
  email: string;
  isMobileClient: boolean;
}

type HandleSubmit<FormValuesT> = (
  handler: (formValues: FormValuesT) => void | Promise<any>,
) => (event: any) => void;

interface ConfirmationUIPropsWithHandler extends ConfirmationUIProps {
  handleSubmit: HandleSubmit<FormValues>;
}

const ConfirmationUI = ({
  redirectUrl,
  email,
  handleSubmit,
  isMobileClient,
}: ConfirmationUIPropsWithHandler) => {
  const context = useAppContext();
  const brand = context.hostConfig.clientConfig.brand || 'seek';

  const { t } = useTranslations(translations);
  const responsiveValue = useResponsiveValue();
  const isMobileView = responsiveValue({
    mobile: true,
    tablet: false,
  });

  const privacyChkBoxText = context.hostConfig.privacyChkBoxText as
    | 'asiaCandidateStandardPrivacyPolicy'
    | 'asiaCandidateThaiPrivacyPolicy'
    | undefined;
  const isANZ = context.locale === 'au' || context.locale === 'nz';
  const privacyLink = getClientHrefLink(context, isMobileClient, '/privacy');

  const { hasConsented } = createValidator({
    test: ({ value }: { value: boolean }) =>
      !Boolean(privacyChkBoxText) || value === true,
    formatErrorMessages: (messages) => () =>
      messages[
        'To create an account, you must agree to the above terms.'
      ].format(),
    translations,
    validatorName: 'hasConsented',
  });

  const privacyAccepted = useField({
    id: 'privacyAccepted',
    validators: [hasConsented],
  });

  const onSubmit = handleSubmit(() => {
    changeLocation(`${redirectUrl}&accountType=new`);
  });

  const Content = (
    <Box
      paddingX={{
        mobile: 'none',
        tablet: 'small',
        desktop: 'medium',
      }}
      paddingY={{ mobile: 'none', tablet: 'small', desktop: 'large' }}
    >
      <form onSubmit={onSubmit} noValidate>
        <Stack align="center" space="large">
          <Heading level="3">{t('Register a new account')}</Heading>
          <Box width="full">
            <Stack space="xsmall">
              <Text align="center" maxLines={1}>
                {t("We didn't find account for", {
                  brand: brandName(brand),
                })}{' '}
              </Text>
              <Text align="center" maxLines={1}>
                <Strong>{email}</Strong>
              </Text>
            </Stack>
          </Box>
          {isANZ ? (
            <Text size="small" align="center">
              {t('By registering you agree to SEEK’s Privacy Policy', {
                PrivacyPolicyLink: (v: any) => (
                  <TextLink href={privacyLink} target="_blank" weight="weak">
                    {v}
                  </TextLink>
                ),
              })}
            </Text>
          ) : null}
          {privacyChkBoxText ? (
            <ConsentCheckbox
              privacyAcceptedController={privacyAccepted}
              privacyChkBoxText={privacyChkBoxText}
              isMobileClient={isMobileClient}
            />
          ) : null}
          <Box width="full">
            <Stack align="center" space="small">
              <Button tone="brandAccent" variant="solid" type="submit">
                {t('Register new account')}
              </Button>
              <ButtonLink
                href={`${redirectUrl}&accountType=existing`}
                tone="neutral"
                variant="soft"
              >
                {t('Back to sign in')}
              </ButtonLink>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      width="full"
      height="full"
      paddingBottom="large"
      className={`${styles.container} ${styles.backgroundColors}`}
    >
      <CandidateHeader />
      <Box paddingTop="medium" height="full" display="flex" alignItems="center">
        <ContentBlock width="small">
          <Stack space="small">
            {isMobileView ? (
              <Box paddingX="small">{Content}</Box>
            ) : (
              <Card>{Content}</Card>
            )}
          </Stack>
        </ContentBlock>
      </Box>
    </Box>
  );
};

const ConfirmationUIWrapper = (props: ConfirmationUIProps) => {
  const { language } = useAppContext();
  return (
    <FormProvider
      initialValues={{
        privacyAccepted: true,
      }}
      language={language}
    >
      {({ handleSubmit }) => (
        <ConfirmationUI handleSubmit={handleSubmit} {...props} />
      )}
    </FormProvider>
  );
};

export default ConfirmationUIWrapper;
