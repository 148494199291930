import { qa_seekbusiness_com_au } from '@seek/online-identity';

import type { RenderConfig } from '../../types';

export default {
  hostConfigs: {
    'seekbusiness.com.au.local': {
      clientConfig: qa_seekbusiness_com_au,
      environment: {},
    },
  },
  hookDomain: 'seekbusiness',
  themeName: 'seekBusiness',
} as RenderConfig;
