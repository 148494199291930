import { useTranslations } from '@vocab/react';
import {
  Box,
  Stack,
  Text,
  TextLink,
  Accordion,
  AccordionItem,
  List,
  Card,
} from 'braid-design-system';
import React from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import type { AppLanguage, Locale } from 'src/types';
import getClientHrefLink from 'src/utils/getClientHrefLink';
import { getLanguagePrefixedPath } from 'src/utils/getLanguage';

import translations from './.vocab';

type Link = Record<string, Record<string, string>>;

const getSetupAccountLink = (
  language: AppLanguage,
  locale: Locale = 'au',
): string => {
  const laguagePrefixedPath = getLanguagePrefixedPath(
    language,
    '/s/article/How-do-I-add-new-users-or-update-user-permissions',
  );
  const asiaEnglishLink = `https://help.${locale}.employer.seek.com${laguagePrefixedPath}`;
  const auEnglishLink = `https://help.seek.com.au/employer${laguagePrefixedPath}`;
  const nzEnglishLink = `https://help.seek.co.nz/employer${laguagePrefixedPath}`;

  const urls: Link = {
    en: {
      hk: asiaEnglishLink,
      ph: asiaEnglishLink,
      my: asiaEnglishLink,
      sg: asiaEnglishLink,
      id: asiaEnglishLink,
      th: `${asiaEnglishLink}?language=en_US`,
      au: auEnglishLink,
      nz: nzEnglishLink,
    },
    id: {
      id: `${asiaEnglishLink}?language=in`,
    },
    th: {
      th: `${asiaEnglishLink}?language=th`,
    },
  };

  return urls[language][locale] || auEnglishLink;
};

const SecondFactorFAQ = () => {
  const context = useAppContext();
  const { language, locale } = context;

  const { t } = useTranslations(translations);

  return (
    <Box paddingBottom="medium" paddingTop="medium" marginTop="large">
      <Card>
        <Stack space="small">
          <Box paddingBottom="medium">
            <Text size="large" weight="strong">
              {t('Need help')}
            </Text>
          </Box>
          <Accordion
            size="standard"
            space="large"
            weight="regular"
            dividers={false}
          >
            <AccordionItem
              id="second_factor_faq_1"
              label={t("I didn't receive the verification email")}
            >
              <Box
                marginTop="small"
                paddingRight={{
                  mobile: 'medium',
                  tablet: 'medium',
                  desktop: 'large',
                }}
              >
                <Stack space="large">
                  <Text tone="secondary">
                    {t(
                      "If you didn't receive the email, here are some things to try.",
                    )}
                  </Text>
                  <Box paddingLeft="medium">
                    <Stack space="large">
                      <List space="large">
                        <Stack space="small">
                          <Text tone="secondary" weight="strong">
                            {t('Give it a few minutes.')}
                          </Text>
                          <Text tone="secondary">
                            {t(
                              'There may be a delay of up to 5 minutes before you receive the verification email.',
                            )}
                          </Text>
                        </Stack>
                        <Stack space="small">
                          <Text tone="secondary" weight="strong">
                            {t('Check your Spam or Junk folder.')}
                          </Text>
                          <Text tone="secondary">
                            {t(
                              'The email may have been accidentally identified as spam.',
                            )}
                          </Text>
                        </Stack>
                        <Stack space="small">
                          <Text tone="secondary" weight="strong">
                            {t("Make sure the email hasn't been blocked.")}
                          </Text>
                          <Text tone="secondary">
                            {t(
                              'SEEK emails and links can become blocked at an Inbox level, a Server level, or an ISP level.',
                            )}
                          </Text>
                        </Stack>
                      </List>
                      <List space="small">
                        <Text tone="secondary">
                          {t(
                            'To prevent this occurring, add the relevant customer service email to your contacts list.',
                          )}
                        </Text>
                        <Text tone="secondary">
                          Australia:{' '}
                          <TextLink href="mailto:customerservice-au@seek.com">
                            customerservice-au@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          New Zealand:{' '}
                          <TextLink href="mailto:customerservice-nz@seek.com">
                            customerservice-nz@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Hong Kong:{' '}
                          <TextLink href="mailto:customerservice-hk@seek.com">
                            customerservice-hk@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Indonesia:{' '}
                          <TextLink href="mailto:customerservice-id@seek.com">
                            customerservice-id@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Philippines:{' '}
                          <TextLink href="mailto:customerservice-ph@seek.com">
                            customerservice-ph@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Malaysia:{' '}
                          <TextLink href="mailto:customerservice-my@seek.com">
                            customerservice-my@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Singapore:{' '}
                          <TextLink href="mailto:customerservice-sg@seek.com">
                            customerservice-sg@seek.com
                          </TextLink>
                        </Text>
                        <Text tone="secondary">
                          Thailand:{' '}
                          <TextLink href="mailto:customerservice-th@seek.com">
                            customerservice-th@seek.com
                          </TextLink>
                        </Text>
                      </List>
                      <List space="large">
                        <Stack space="small">
                          <Text tone="secondary">
                            {t(
                              'Check your rules or filters to see if these may have directed the email away from your inbox.',
                            )}
                          </Text>
                          <Text tone="secondary">
                            {t(
                              'Check with your IT department to see if they have any rules in place to block or redirect SEEK emails.',
                            )}
                          </Text>
                        </Stack>
                        <Stack space="small">
                          <Text tone="secondary" weight="strong">
                            {t('Check with your colleague.')}
                          </Text>
                          <Text tone="secondary">
                            {t(
                              "Sharing an Inbox with a colleague? Check if they've seen the email.",
                            )}
                          </Text>
                        </Stack>
                      </List>
                    </Stack>
                  </Box>
                  <Text tone="secondary">
                    {t(
                      "If you've tried these options and still can't receive the email, call our Customer Service team.",
                      {
                        CustomerServiceLink: (v) => (
                          <TextLink
                            href={getClientHrefLink(
                              context,
                              false,
                              '/contactus',
                            )}
                            target="_blank"
                          >
                            {v}
                          </TextLink>
                        ),
                      },
                    )}
                  </Text>
                </Stack>
              </Box>
            </AccordionItem>
            <AccordionItem
              id="second_factor_faq_2"
              label={t("My email is going to a team member's account")}
            >
              <Box
                marginTop="small"
                paddingRight={{
                  mobile: 'medium',
                  tablet: 'medium',
                  desktop: 'large',
                }}
              >
                <Stack space="large">
                  <Text tone="secondary">
                    {t(
                      'Your team account may not be set up correctly or you might be sharing the same username and password with your colleague.',
                    )}
                  </Text>
                  <Text tone="secondary">
                    {t(
                      'Did you know your team can share the same account and each team member can have their own username and password?',
                    )}
                  </Text>
                  <Text tone="secondary">
                    {t(
                      'Sharing the same username and password with a team member is not possible.',
                    )}{' '}
                    {t(
                      'Each username can only use one mobile number for multi-factor authentication.',
                    )}
                  </Text>
                  <Text tone="secondary">
                    {t(
                      'Find out how to set up your account so each team member has their own sign in details.',
                      {
                        SetUpAccountLink: (v) => (
                          <TextLink
                            href={getSetupAccountLink(language, locale)}
                            target="_blank"
                          >
                            {v}
                          </TextLink>
                        ),
                      },
                    )}
                  </Text>
                </Stack>
              </Box>
            </AccordionItem>
            <AccordionItem
              id="second_factor_faq_3"
              label={t(
                "The email is being sent to an inbox I don't have access to",
              )}
            >
              <Box
                marginTop="small"
                paddingRight={{
                  mobile: 'medium',
                  tablet: 'medium',
                  desktop: 'large',
                }}
              >
                <Stack space="large">
                  <Text tone="secondary">
                    {t(
                      "If you've changed email address and no longer can access your inbox, call our Customer Service.",
                      {
                        CustomerServiceLink: (v) => (
                          <TextLink
                            href={getClientHrefLink(
                              context,
                              false,
                              '/contactus',
                            )}
                            target="_blank"
                          >
                            {v}
                          </TextLink>
                        ),
                      },
                    )}
                    <br />
                    {t(
                      "We'll ask you some questions to help us identify you. Then we can help change your email address.",
                    )}
                  </Text>
                  <Text tone="secondary">
                    {t('For more information, see Protect Yourself Online', {
                      ProtectOnlineLink: (v) => (
                        <TextLink
                          href={getClientHrefLink(
                            context,
                            false,
                            '/protect-yourself-online',
                          )}
                          target="_blank"
                        >
                          {v}
                        </TextLink>
                      ),
                    })}
                  </Text>
                </Stack>
              </Box>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Card>
    </Box>
  );
};
export default SecondFactorFAQ;
