import React from 'react';

import {
  siteNames,
  type RenderConfig,
  type SiteName,
} from 'src/modules/configuration';
import type { HostConfig } from 'src/modules/configuration/types';
import type { AppLanguage, Locale } from 'src/types';

import type { Auth0TenantName } from '../../utils/tenants';

export interface ContextProps {
  language: AppLanguage;
  site: SiteName;
  tenant: Auth0TenantName;
  environment: string;
  renderConfig: RenderConfig;
  locale?: Locale;
  hostConfig: HostConfig;
}

const AppContext = React.createContext<ContextProps>({
  language: 'en',
  site: siteNames.candidate.seek.au,
  tenant: 'seekAnz',
  environment: 'development',
  renderConfig: {} as any,
  locale: 'au',
  hostConfig: {} as any,
});

export const useAppContext = (): ContextProps => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
};

export default AppContext;
