/* eslint-disable no-console */
import { FragmentHandler } from '@seek/oauth-url-builders';
import type { LoginOptions } from '@seek/online-identity';
import HostedPages from '@seek/online-identity-auth0-hosted-pages';
import { Experience } from '@seek/online-identity-auth0-hosted-pages/src/types';
import { VocabProvider } from '@vocab/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { useAppContext } from 'src/components/App/AppContext';
import { useAuthN } from 'src/components/hooks/useAuthN';
import LoadingScreen from 'src/components/shared/LoadingScreen/LoadingScreen';
import getHook from 'src/modules/hooks/getHook';
import type { HookConfig, Hook } from 'src/modules/hooks/types';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import { trackHookDuration, count } from 'src/modules/tracking/metrics';
import type { LoginPath } from 'src/types';
import getAdditionalFragmentParameters from 'src/utils/getAdditionalFragmentParameters';
import getCookies from 'src/utils/getCookies';
import getSearchParams from 'src/utils/getSearchParams';
import isSeo from 'src/utils/isSeo';

const {
  LoginForm,
  RegistrationForm,
  ForgotPasswordForm,
  LoginPageProvider,
  MainLayout,
} = HostedPages;

const PATH_PREFIX = '/oauth';

const HOOK_TYPE: Hook = 'pre-login';

interface Props {
  path: LoginPath;
}

const Login = ({ path = '/' }: Props) => {
  const { analytics } = useAnalyticsContext();
  const authN = useAuthN();

  const {
    hostConfig,
    renderConfig: { hookDomain, originWhitelist = [] },
    tenant,
    language,
    locale,
  } = useAppContext();

  const isShowingLoginPage =
    isSeo() &&
    tenant === 'seekAnz' &&
    ['/login', '/register', '/forgot-password'].includes(path);

  useEffect(() => {
    const exec = async () => {
      const hook = await getHook(HOOK_TYPE, hookDomain);

      const disableHook =
        path === '/forgot-password' || hostConfig.disableHooks;

      if (hook && !disableHook) {
        const startTime = Date.now();
        const config: HookConfig = {
          authN,
          hostConfig,
          originWhitelist,
          analytics,
        };
        const shouldContinue = await hook(config, path, language, locale);
        trackHookDuration(
          HOOK_TYPE,
          hookDomain!,
          startTime,
          shouldContinue ? 'true' : 'false',
        );

        if (!shouldContinue) {
          return;
        }
      }

      const searchParams = getSearchParams();
      const { realm, error_description, msg, returnPath } = searchParams;

      const { da_cdt } = getCookies();
      const fragment = FragmentHandler.stringify(path, {
        login_msg: msg ? msg : error_description,
        locale,
        language,
        realm: realm || 'Username-Password-Authentication',
        da_cdt,
        ...getAdditionalFragmentParameters(path, searchParams),
      });

      const loginConfig: LoginOptions = {
        returnUri: returnPath,
        fragment,
        additionalArguments: {
          language: `${language}-${locale?.toUpperCase()}`,
        },
      };

      if (path === '/forgot-password') {
        loginConfig.additionalArguments = {
          ...loginConfig.additionalArguments,
          prompt: 'login',
        };
      }

      count('generic_login_initiated');
      authN?.login(loginConfig);
    };
    if (!isShowingLoginPage) {
      exec();
    }
  }, [
    analytics,
    authN,
    path,
    hookDomain,
    hostConfig,
    isShowingLoginPage,
    originWhitelist,
    tenant,
    language,
    locale,
  ]);

  if (isShowingLoginPage) {
    return (
      <VocabProvider language={language}>
        <LoginPageProvider
          experience={Experience.Candidate}
          environment="production"
          locale={locale ?? 'au'}
        >
          <MainLayout>
            <Routes>
              <Route
                path={`${PATH_PREFIX}/login`}
                element={
                  <>
                    <Helmet>
                      {/* To prevent Google indexing all permutations of the query param. Only index /oauth/login */}
                      {window.location.href.includes('?') && (
                        <meta name="robots" content="noindex,nofollow" />
                      )}
                    </Helmet>
                    <LoginForm />
                  </>
                }
              />
              <Route
                path={`${PATH_PREFIX}/register`}
                element={<RegistrationForm />}
              />
              <Route
                path={`${PATH_PREFIX}/forgot-password`}
                element={<ForgotPasswordForm />}
              />
            </Routes>
          </MainLayout>
        </LoginPageProvider>
      </VocabProvider>
    );
  }

  return (
    <>
      <LoadingScreen />
    </>
  );
};

export default Login;
