import {
  siteNames as melwaysSiteNames,
  siteConfig as melwaysSiteConfig,
  getLocalizedRoutes,
  type SiteName as MelwaysSiteName,
} from '@seek/melways-sites';

export const siteNames = {
  ...melwaysSiteNames,
  business: {
    seek: { au: 'seekBusiness' },
  },
} as const;

export const siteConfig = {
  ...melwaysSiteConfig,
  [siteNames.business.seek.au]: {
    host: 'www.seekbusiness.com.au',
    stagingHost: 'qa.seekbusiness.com.au',
    languages: ['en'],
    classification: {
      product: 'business',
      brand: 'seek',
      country: 'au',
    },
  },
};

const { candidate, employer, business } = siteNames;
export const PATH_PREFIX = '/oauth';

export const AppRoute = {
  Debug: 'debug',
  Login: 'login',
  Logout: 'logout',
  Callback: 'callback',
  Integration: 'integration',
  ForgotPassword: 'forgot-password',
  Register: 'register',
  EmailLookup: 'email-lookup',
  Error: 'error',
  Redirect: 'redirect',
  SecondFactorEnrolment: 'second-factor-enrolment',
  MfaChallengeAnalytics: 'mfa-challenge-analytics',
  SecondFactorEnrolmentOptIn: 'second-factor-enrolment-opt-in',
  Ticket: 'ticket',
  SigninConfirmation: 'signin-confirmation',
} as const;

const ENABLED_ROUTES = [
  {
    route: `${PATH_PREFIX}/debug`,
    name: AppRoute.Debug,
  },
  {
    route: `${PATH_PREFIX}/login`,
    name: AppRoute.Login,
  },
  {
    route: `${PATH_PREFIX}/logout`,
    name: AppRoute.Logout,
  },
  {
    route: `${PATH_PREFIX}/callback`,
    name: AppRoute.Callback,
  },
  {
    route: `${PATH_PREFIX}/integration`,
    name: AppRoute.Integration,
  },
  {
    route: `${PATH_PREFIX}/forgot-password`,
    name: AppRoute.ForgotPassword,
  },
  {
    route: `${PATH_PREFIX}/register`,
    name: AppRoute.Register,
  },
  {
    route: `${PATH_PREFIX}/email-lookup`,
    name: AppRoute.EmailLookup,
  },
  {
    route: `${PATH_PREFIX}/error`,
    name: AppRoute.Error,
  },
  {
    route: `${PATH_PREFIX}/redirect`,
    name: AppRoute.Redirect,
  },
  {
    route: `${PATH_PREFIX}/second-factor-enrolment`,
    name: AppRoute.SecondFactorEnrolment,
  },
  {
    route: `${PATH_PREFIX}/mfa-challenge-analytics`,
    name: AppRoute.MfaChallengeAnalytics,
  },
  {
    route: `${PATH_PREFIX}/second-factor-enrolment-opt-in`,
    name: AppRoute.SecondFactorEnrolmentOptIn,
  },
  {
    route: `${PATH_PREFIX}/ticket`,
    name: AppRoute.Ticket,
  },
  {
    route: `${PATH_PREFIX}/signin-confirmation`,
    name: AppRoute.SigninConfirmation,
  },
] as const;

export type SiteName = MelwaysSiteName | typeof business.seek.au;

export const ENABLED_SITES: readonly SiteName[] = [
  employer.seek.au,
  employer.seek.nz,
  employer.seek.my,
  employer.seek.sg,
  employer.seek.id,
  employer.seek.ph,
  employer.seek.hk,
  employer.seek.th,
  candidate.seek.au,
  candidate.seek.nz,
  candidate.jobstreet.my,
  candidate.jobstreet.sg,
  candidate.jobstreet.id,
  candidate.jobstreet.ph,
  candidate.jobsdb.hk,
  candidate.jobsdb.th,
  business.seek.au,
] as const;

export const melwaysLocalizeRoutesForSite = getLocalizedRoutes(ENABLED_ROUTES);
export const localizeRoutesForSite = (site: SiteName) =>
  site === business.seek.au
    ? // This is a workaround to allow the seekbusiness to use the melways localization since none provided
      ENABLED_ROUTES.map((r) => ({
        ...r,
        language: 'en-AU',
        languages: ['en-AU'],
      }))
    : melwaysLocalizeRoutesForSite(site);

export const getHostName = ({
  site,
  staging,
}: {
  site: SiteName;
  staging: boolean;
}): string => {
  const { host, stagingHost } = siteConfig[site];

  return staging ? stagingHost : host;
};
