import { Box } from 'braid-design-system';
import React from 'react';

import Logo from 'src/components/shared/Logo/Logo';

import * as styles from './CandidateHeader.css';

const Header = () => (
  <Box
    paddingX="gutter"
    paddingY="small"
    data-testid="header"
    className={styles.container}
  >
    <Logo />
  </Box>
);

export default Header;
