import { Buffer } from 'buffer';

import type { LoginPath } from 'src/types';

import type { SearchParams } from './getSearchParams';

const getAdditionalFragmentParameters = (
  path: LoginPath,
  searchParams: SearchParams,
) => {
  const { error_description, email } = searchParams;
  switch (true) {
    case path === '/forgot-password' &&
      error_description === 'error.expired_password':
      return {
        email_hint: Buffer.from(email, 'base64').toString('utf8'),
      };
    default:
      return;
  }
};

export default getAdditionalFragmentParameters;
