import type {
  HookDomain,
  Hook,
  PreLoginHook,
  PostLoginHook,
  PreLogoutHook,
} from './types';

/**
 * A generic function to import hooks for all domains and hook types.
 * This file can be imported with proper 'import' syntax from anywhere, and it will
 * then import the hook; allowing the callee to not have to worry about where exactly the
 * hooks are stored.
 *
 * @param hook eg: pre-login, post-login
 * @param domain eg: hirer, candidate
 */

function getHook(
  hook: 'pre-login',
  domain?: HookDomain,
): Promise<PreLoginHook | undefined>;
function getHook(
  hook: 'post-login',
  domain?: HookDomain,
): Promise<PostLoginHook | undefined>;
function getHook(
  hook: 'pre-logout',
  domain?: HookDomain,
): Promise<PreLogoutHook | undefined>;
async function getHook(hook: Hook, domain?: HookDomain) {
  if (!hook) {
    throw new Error('hook not supplied.');
  }

  if (!domain) {
    return;
  }

  const importedHook = await import(
    /* webpackChunkName: "dynamic-hook"  */ `./${domain}/${hook}`
  );
  return importedHook.default;
}

export default getHook;
